import { GoogleGenerativeAI } from "@google/generative-ai";

// Initialize the API
const genAI = new GoogleGenerativeAI(process.env.REACT_APP_GEMINI_API_KEY);

export async function callGeminiAPI(imageData) {
  try {
    // Choose the Gemini API model
    const model = genAI.getGenerativeModel({ model: "gemini-1.5-flash" });

    // Update the prompt to be more specific about number formatting
    const prompt = `
    Analyze the provided image of an invoice and extract the following information.If there are multiple transactions or invoices, combine and sum up the relevant numeric fields. Ensure the output is in complete and accurate JSON format, and include all fields as specified:
    
    - username: The name of the customer or user mentioned in the invoice, make sure mention the full name.
    - email: The email address of the customer, if available. If not available, leave the field as null.
    - mobile_number: The mobile number of the merchant, if available. If not available, leave the field as null.
    - merchant_name: The name of the merchant or store that issued the invoice.
    - bill_no: The bill number mentioned on the invoice.
    - item_names: A list of items purchased, including the item name only.
    - purchase_summary: A short descriptive summary of invoice.
    - tax_amount: Extract the total tax amount (CGST + SGST + other taxes) as a single decimal number with exactly 2 decimal places. For example: 30432.24
    - total_amount: Extract the final amount paid including all taxes as a single decimal number with exactly 2 decimal places. For example: 199500.00
    - gst_number: The merchant's GST number.
    - address: The complete address of the merchant/store mentioned in the invoice.
    - transaction_time: The exact date and time of the transaction in ISO format (YYYY-MM-DD).
    - discount_amount: Any discount applied to the total, if available. If no discount is applied, set the value to 0.
    - invoice_category: Determine the category of the invoice (Grocery, Clothing, Travel, Electronics, Dining, Entertainment, Healthcare, or Other).

    Format all numeric values as plain numbers without commas or currency symbols. Ensure dates are in YYYY-MM-DD format.`;

    // Generate content by prompting the model
    const result = await model.generateContent([
      prompt,
      {
        inlineData: {
          mimeType: "image/jpeg",
          data: imageData,
        },
      },
    ]);
    const response = await result.response;
    const text = response.text();

    // Extract JSON from the markdown response
    const jsonMatch = text.match(/```json\n([\s\S]*?)\n```/);
    if (jsonMatch && jsonMatch[1]) {
      let jsonString = jsonMatch[1].trim();

      // Clean up the JSON string
      jsonString = jsonString
        .replace(/\n/g, "")
        .replace(/,\s*}/g, "}")
        .replace(
          /(\d+)\.(\d+)\.(\d+)/g,
          (match, p1, p2, p3) => `${p1}${p2}.${p3}`
        ) // Fix numbers like 30.432.24 -> 30432.24
        .replace(/,\s*,/g, ",") // Remove duplicate commas
        .replace(/\s+/g, " "); // Normalize whitespace

      try {
        let parsedResult = JSON.parse(jsonString);

        // Format numeric values
        if (typeof parsedResult.tax_amount === "string") {
          parsedResult.tax_amount = parseFloat(
            parsedResult.tax_amount.replace(/[^\d.-]/g, "")
          );
        }
        if (typeof parsedResult.total_amount === "string") {
          parsedResult.total_amount = parseFloat(
            parsedResult.total_amount.replace(/[^\d.-]/g, "")
          );
        }
        if (typeof parsedResult.discount_amount === "string") {
          parsedResult.discount_amount =
            parseFloat(parsedResult.discount_amount.replace(/[^\d.-]/g, "")) ||
            0;
        }

        // Format date
        if (parsedResult.transaction_time) {
          const date = new Date(parsedResult.transaction_time);
          if (!isNaN(date.getTime())) {
            parsedResult.transaction_time = date.toISOString().split("T")[0];
          }
        }

        // Ensure all required fields are present
        const requiredFields = [
          "username",
          "email",
          "mobile_number",
          "merchant_name",
          "bill_no",
          "item_names",
          "purchase_summary",
          "tax_amount",
          "total_amount",
          "gst_number",
          "address",
          "transaction_time",
          "discount_amount",
          "invoice_category",
        ];

        requiredFields.forEach((field) => {
          if (parsedResult[field] === undefined) {
            parsedResult[field] = null;
          }
        });

        return parsedResult;
      } catch (parseError) {
        console.error("Error parsing JSON:", parseError);
        console.log("Problematic JSON string:", jsonString);
        throw new Error("Failed to parse JSON from API response");
      }
    } else {
      throw new Error("Unable to extract JSON from the response");
    }
  } catch (error) {
    console.error("Error calling Gemini API:", error);
    throw error;
  }
}
