import React from "react";
import { useNavigate } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Avatar,
  Box,
  Grid,
  Card,
  CardContent,
  Button,
  useMediaQuery,
  Container,
} from "@mui/material";
import { styled } from "@mui/system";
import ExpenseTracker from "./ExpenseTracker";
import { useAuth } from "../AuthContext";

const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: "#003C8E",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  padding: "24px",
  borderRadius: "4px",
  transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
  "&:hover": {
    transform: "translateY(-5px)",
    boxShadow: "0 4px 20px 0 rgba(0,0,0,0.12)",
  },
}));

const PlanTitle = styled(Typography)({
  fontWeight: "bold",
  marginBottom: "16px",
  textAlign: "center",
  color: "#ffffff",
  fontFamily: "Proxima-Nova-Font, sans-serif",
  fontSize: "1.75rem",
});

const PlanPrice = styled(Typography)({
  fontWeight: "bold",
  fontSize: "2rem",
  marginBottom: "8px",
  textAlign: "center",
  color: "#ffffff",
  fontFamily: "Proxima-Nova-Font, sans-serif",
});

const PlanDuration = styled(Typography)({
  marginBottom: "24px",
  textAlign: "center",
  color: "#ffffff",
  fontFamily: "Proxima-Nova-Font, sans-serif",
  fontSize: "1rem",
});

const SubscriptionPlan = ({ title, price, duration, onClick }) => (
  <Grid item xs={12} sm={6} md={4}>
    <StyledCard elevation={3}>
      <CardContent>
        <PlanTitle variant="h5">{title}</PlanTitle>
        <PlanPrice variant="h4">${price}</PlanPrice>
        <PlanDuration variant="body1">Up to {duration}</PlanDuration>
      </CardContent>
      <Button
        variant="contained"
        fullWidth
        onClick={onClick}
        sx={{
          backgroundColor: "#FFE392",
          color: "#000000",
          textTransform: "none",
          fontFamily: "Proxima-Nova-Font, sans-serif",
          fontWeight: "bold",
          fontSize: "1rem",
          padding: "12px",
          "&:hover": {
            backgroundColor: "#D9B441", // Hover background color
          },
        }}
      >
        Buy Now
      </Button>
    </StyledCard>
  </Grid>
);

const SubscriptionPlans = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:600px)");
  const isTablet = useMediaQuery("(max-width:960px)");
  const { userID } = useAuth();

  const handleButtonClick = () => {
    if (userID) {
      navigate("/et");
    } else {
      navigate("/");
    }
  };

  const plans = [
    { title: "Pro", price: 99, duration: "3 Months" },
    { title: "Premium", price: 150, duration: "3 Months" },
    { title: "Enterprise", price: 299, duration: "12 Months" },
  ];

  return (
    <Box
      sx={{
        flexGrow: 1,
        backgroundColor: "#f5f5f5",
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        marginBottom: "70px",
        marginTop: "50px",
      }}
    >
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4, px: isMobile ? 2 : 3 }}>
        <Typography
          variant="h3"
          align="center"
          sx={{
            mb: 6,
            fontFamily: "Proxima-Nova-Font, sans-serif",
            fontWeight: "bold",
            fontSize: isMobile ? 28 : isTablet ? 32 : 36,
            color: "#3f51b5",
          }}
        >
          Subscription Plans
        </Typography>
        <Grid container spacing={isMobile ? 2 : 4} justifyContent="center">
          {plans.map((plan, index) => (
            <SubscriptionPlan
              key={index}
              {...plan}
              onClick={handleButtonClick}
            />
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default SubscriptionPlans;
