import React from "react";
import { Box, Typography, Paper, Button } from "@mui/material";
import { BarChart2 } from "lucide-react";

const NoTransactionsBox = () => {
  return (
    <Paper
      elevation={2}
      sx={{
        maxWidth: 500,
        margin: "auto",
        overflow: "hidden",
        borderRadius: 2,
      }}
    >
      <Box
        sx={{
          bgcolor: "primary.main",
          py: 2,
          px: 3,
          display: "flex",
          alignItems: "center",
        }}
      >
        <BarChart2 color="white" size={24} />
        <Typography variant="h6" color="white" sx={{ ml: 2 }}>
          Transaction History
        </Typography>
      </Box>
      <Box
        sx={{
          py: 4,
          px: 3,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography variant="subtitle1" gutterBottom>
          No Transactions Available
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          align="center"
          sx={{ mb: 3 }}
        >
          Your transaction history is currently empty. Transactions will appear
          here once you start making transfers or purchases.
        </Typography>
      </Box>
    </Paper>
  );
};

export default NoTransactionsBox;
