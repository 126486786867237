import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import MuiCard from "@mui/material/Card";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import getSignUpTheme from "./theme/getSignUpTheme";
import { GoogleIcon, FacebookIcon, SitemarkIcon } from "./CustomIcons";
import TemplateFrame from "./TemplateFrame";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../AuthContext";
import SnackbarAlert from "../components/SnackbarAlert";
import logo from "../assets/BillWeaver.png";

const Card = styled(MuiCard)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignSelf: "center",
  width: "100%",
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  margin: "auto",
  maxWidth: "450px",
  backgroundColor: "#003C8E",
  boxShadow:
    "hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px",
  [theme.breakpoints.up("sm")]: {
    width: "450px",
  },
  ...theme.applyStyles("light", {
    boxShadow:
      "hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px",
  }),
}));

const SignUpContainer = styled(Stack)(({ theme }) => ({
  minHeight: "100vh",
  padding: theme.spacing(2),
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(4),
  },
  backgroundImage:
    "radial-gradient(ellipse at 50% 50%, hsl(210, 100%, 97%), hsl(0, 0%, 100%))",
  backgroundRepeat: "no-repeat",
  ...theme.applyStyles("dark", {
    backgroundImage:
      "radial-gradient(at 50% 50%, hsla(210, 100%, 16%, 0.5), hsl(220, 30%, 5%))",
  }),
}));

export default function SignUp() {
  const [mode, setMode] = React.useState("light");
  const [showCustomTheme, setShowCustomTheme] = React.useState(true);
  const defaultTheme = createTheme({ palette: { mode } });
  const SignUpTheme = createTheme(getSignUpTheme(mode));
  const [nameError, setNameError] = React.useState(false);
  const [nameErrorMessage, setNameErrorMessage] = React.useState("");
  const [emailError, setEmailError] = React.useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = React.useState("");
  const [passwordError, setPasswordError] = React.useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = React.useState("");
  const [receiveUpdates, setReceiveUpdates] = React.useState(false);

  // Snackbar state
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");

  // This code only runs on the client side, to determine the system color preference
  React.useEffect(() => {
    // Check if there is a preferred mode in localStorage
    const savedMode = localStorage.getItem("themeMode");
    if (savedMode) {
      setMode(savedMode);
    } else {
      // If no preference is found, it uses system preference
      const systemPrefersDark = window.matchMedia(
        "(prefers-color-scheme: dark)"
      ).matches;
      setMode(systemPrefersDark ? "dark" : "light");
    }
  }, []);

  const toggleColorMode = () => {
    const newMode = mode === "dark" ? "light" : "dark";
    setMode(newMode);
    localStorage.setItem("themeMode", newMode); // Save the selected mode to localStorage
  };

  const toggleCustomTheme = () => {
    setShowCustomTheme((prev) => !prev);
  };

  // const validateInputs = () => {
  //   const email = document.getElementById("email");
  //   const password = document.getElementById("password");
  //   const name = document.getElementById("name");

  //   let isValid = true;

  //   if (!email.value || !/\S+@\S+\.\S+/.test(email.value)) {
  //     setEmailError(true);
  //     setEmailErrorMessage("Please enter a valid email address.");
  //     isValid = false;
  //   } else {
  //     setEmailError(false);
  //     setEmailErrorMessage("");
  //   }

  //   if (!password.value || password.value.length < 6) {
  //     setPasswordError(true);
  //     setPasswordErrorMessage("Password must be at least 6 characters long.");
  //     isValid = false;
  //   } else {
  //     setPasswordError(false);
  //     setPasswordErrorMessage("");
  //   }

  //   if (!name.value || name.value.length < 1) {
  //     setNameError(true);
  //     setNameErrorMessage("Name is required.");
  //     isValid = false;
  //   } else {
  //     setNameError(false);
  //     setNameErrorMessage("");
  //   }

  //   return isValid;
  // };

  // const handleSubmit = (event) => {
  //   if (nameError || emailError || passwordError) {
  //     event.preventDefault();
  //     return;
  //   }
  //   const data = new FormData(event.currentTarget);
  //   console.log({
  //     name: data.get("name"),
  //     lastName: data.get("lastName"),
  //     email: data.get("email"),
  //     password: data.get("password"),
  //   });
  // };

  const navigate = useNavigate();
  const { login } = useAuth();
  const validateInputs = () => {
    const email = document.getElementById("email");
    const password = document.getElementById("password");
    const name = document.getElementById("name");

    let isValid = true;

    if (!email.value || !/\S+@\S+\.\S+/.test(email.value)) {
      setEmailError(true);
      setEmailErrorMessage("Please enter a valid email address.");
      isValid = false;
    } else {
      setEmailError(false);
      setEmailErrorMessage("");
    }

    if (!password.value || password.value.length < 6) {
      setPasswordError(true);
      setPasswordErrorMessage("Password must be at least 6 characters long.");
      isValid = false;
    } else {
      setPasswordError(false);
      setPasswordErrorMessage("");
    }

    if (!name.value || name.value.length < 1) {
      setNameError(true);
      setNameErrorMessage("Name is required.");
      isValid = false;
    } else {
      setNameError(false);
      setNameErrorMessage("");
    }

    return isValid;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const isValid = validateInputs();
    if (!isValid) {
      return;
    }

    try {
      const nameElement = document.getElementById("name");
      const emailElement = document.getElementById("email");
      const passwordElement = document.getElementById("password");

      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/signup`,
        {
          fullname: nameElement.value,
          email: emailElement.value,
          password: passwordElement.value,
          receive_updates: receiveUpdates,
        }
      );

      if (response.status === 201) {
        // Log the user in after successful sign-up
        login(response.data.userid);
        navigate("/sub");
      } else {
        setSnackbarMessage("Sign-up failed: " + response.data.message);
        setSnackbarSeverity("error");
        setSnackbarOpen(true); // Open Snackbar for failure
      }
    } catch (error) {
      // console.error("There was an error during sign-up:", error);
      // setSnackbarMessage("An error occurred during sign-up. Please try again.");
      // setSnackbarSeverity("error");
      // setSnackbarOpen(true); // Open Snackbar for error

      if (error.response) {
        setSnackbarMessage(error.response.data.message || "Sign-up failed.");
      } else {
        setSnackbarMessage("An error occurred. Please try again.");
      }
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return false;
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const Logo = styled("img")({
    width: "100px",
    height: "100px",
    marginBottom: "16px",
    display: "block", // Ensures the image is treated as a block element
    marginLeft: "auto", // Centers the image horizontally
    marginRight: "auto", // Centers the image horizontally
  });

  return (
    // <TemplateFrame
    //   toggleCustomTheme={toggleCustomTheme}
    //   showCustomTheme={showCustomTheme}
    //   mode={mode}
    //   toggleColorMode={toggleColorMode}
    // >
    <ThemeProvider theme={showCustomTheme ? SignUpTheme : defaultTheme}>
      {/* <CssBaseline enableColorScheme /> */}
      <SignUpContainer direction="column" justifyContent="space-between">
        <Card variant="outlined">
          {/* <SitemarkIcon /> */}
          <Logo
            src={logo}
            alt="BillWeaver Logo"
            style={{ marginBottom: "0px" }}
          />

          {/* <Typography
            component="h1"
            variant="h4"
            sx={{ width: "100%", fontSize: "clamp(2rem, 10vw, 2.15rem)" }}
          >
            Sign up
          </Typography> */}
          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{ display: "flex", flexDirection: "column", gap: 2 }}
          >
            <FormControl>
              <FormLabel
                htmlFor="name"
                sx={{
                  color: "white", // Set the text color to white
                  fontFamily: "Proxima-Nova-Font, sans-serif", // Optional: Set a custom font
                }}
              >
                Full name
              </FormLabel>

              <TextField
                autoComplete="name"
                name="name"
                required
                fullWidth
                id="name"
                placeholder="Jon Snow"
                error={nameError}
                helperText={nameErrorMessage}
                color={nameError ? "error" : "primary"}
                InputProps={{
                  sx: {
                    fontFamily: "Proxima-Nova-Font, sans-serif", // Apply font family to input text
                    "&::placeholder": {
                      fontFamily: "Proxima-Nova-Font, sans-serif", // Apply font family to placeholder
                    },
                  },
                }}
                sx={{
                  fontFamily: "Proxima-Nova-Font, sans-serif", // Optional: Set a custom font
                }}
              />
            </FormControl>
            <FormControl>
              <FormLabel
                htmlFor="email"
                sx={{
                  color: "white", // Set the text color to white
                  fontFamily: "Proxima-Nova-Font, sans-serif", // Optional: Set a custom font
                }}
              >
                Email
              </FormLabel>
              <TextField
                required
                fullWidth
                id="email"
                placeholder="your@email.com"
                name="email"
                autoComplete="email"
                variant="outlined"
                error={emailError}
                helperText={emailErrorMessage}
                color={passwordError ? "error" : "primary"}
                InputProps={{
                  sx: {
                    fontFamily: "Proxima-Nova-Font, sans-serif", // Apply font family to input text
                    "&::placeholder": {
                      fontFamily: "Proxima-Nova-Font, sans-serif", // Apply font family to placeholder
                    },
                  },
                }}
                sx={{
                  fontFamily: "Proxima-Nova-Font, sans-serif", // Optional: Set a custom font
                }}
              />
            </FormControl>
            <FormControl>
              <FormLabel
                htmlFor="password"
                sx={{
                  color: "white", // Set the text color to white
                  fontFamily: "Proxima-Nova-Font, sans-serif", // Optional: Set a custom font
                }}
              >
                Password
              </FormLabel>
              <TextField
                required
                fullWidth
                name="password"
                placeholder="••••••"
                type="password"
                id="password"
                autoComplete="new-password"
                variant="outlined"
                error={passwordError}
                helperText={passwordErrorMessage}
                color={passwordError ? "error" : "primary"}
                InputProps={{
                  sx: {
                    fontFamily: "Proxima-Nova-Font, sans-serif", // Apply font family to input text
                    "&::placeholder": {
                      fontFamily: "Proxima-Nova-Font, sans-serif", // Apply font family to placeholder
                    },
                  },
                }}
                sx={{
                  fontFamily: "Proxima-Nova-Font, sans-serif", // Optional: Set a custom font
                }}
              />
            </FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  checked={receiveUpdates}
                  onChange={(e) => setReceiveUpdates(e.target.checked)}
                  color="primary"
                />
              }
              label={
                <span
                  style={{
                    color: "white",
                    fontFamily: "Proxima-Nova-Font, sans-serif",
                  }}
                >
                  Receive updates via email.
                </span>
              }
            />
            <Button
              type="submit"
              fullWidth
              //variant="contained"
              onClick={validateInputs}
              sx={{
                backgroundColor: "#FFE392", // Default background color
                "&:hover": {
                  backgroundColor: "#D9B441", // Hover background color
                },
                "&:disabled": {
                  backgroundColor: "#FFE392", // Ensure disabled state doesn't change color
                },
                color: "black", // Text color
                boxShadow: "none", // Remove any default shadow
                fontFamily: "Proxima-Nova-Font, sans-serif",
              }}
            >
              Sign up
            </Button>

            <Typography
              sx={{
                textAlign: "center",
                color: "white",
                fontFamily: "Proxima-Nova-Font, sans-serif",
              }}
            >
              Already have an account?{" "}
              <span>
                <Link
                  href="/"
                  variant="body2"
                  sx={{
                    alignSelf: "center",
                    color: "white",
                    fontFamily: "Proxima-Nova-Font, sans-serif",
                    "&:hover": {
                      color: "#D9B441", // Hover text color
                    },
                  }}
                >
                  Sign in
                </Link>
              </span>
            </Typography>
          </Box>
          {/* <Divider>
              <Typography sx={{ color: "text.secondary" }}>or</Typography>
            </Divider>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <Button
                fullWidth
                variant="outlined"
                onClick={() => alert("Sign up with Google")}
                startIcon={<GoogleIcon />}
              >
                Sign up with Google
              </Button>
              <Button
                fullWidth
                variant="outlined"
                onClick={() => alert("Sign up with Facebook")}
                startIcon={<FacebookIcon />}
              >
                Sign up with Facebook
              </Button>
            </Box> */}
        </Card>

        {/* Snackbar for success or error alerts */}
        <SnackbarAlert
          snackbarOpen={snackbarOpen}
          snackbarMessage={snackbarMessage}
          snackbarSeverity={snackbarSeverity}
          handleSnackbarClose={handleSnackbarClose}
        />
      </SignUpContainer>
    </ThemeProvider>
    // </TemplateFrame>
  );
}
