import React, { createContext, useState, useContext, useEffect } from "react";
import axios from "axios";
import { useAuth } from "./AuthContext";

const CurrencyContext = createContext();

export const CurrencyProvider = ({ children }) => {
  const [currency, setCurrency] = useState("₹");
  const { userID } = useAuth();

  useEffect(() => {
    if (userID) {
      fetchCurrency();
    }
  }, [userID]);

  const fetchCurrency = async () => {
    if (!userID) return;

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/user_settings/${userID}`
      );
      if (response.data && response.data.default_currency) {
        setCurrency(response.data.default_currency);
      } else {
        setCurrency("₹");
      }
    } catch (error) {
      console.error("Error fetching currency:", error);
      // Set a default currency if there's an error
      setCurrency("₹");
    }
  };

  return (
    <CurrencyContext.Provider value={{ currency, fetchCurrency }}>
      {children}
    </CurrencyContext.Provider>
  );
};

export const useCurrency = () => useContext(CurrencyContext);
