import React from "react";
import { Box, Typography, Container } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const Footer = () => {
  const theme = useTheme();

  return (
    <Box
      component="footer"
      sx={{
        // backgroundColor: theme.palette.primary.main,
        backgroundColor: "#FFE392",
        // color: theme.palette.primary.contrastText,
        color: "black",
        py: 1.5,
        width: "100%",
        height: "64px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexShrink: 0,
        fontFamily: "Proxima-Nova-Font, sans-serif",
        "&:hover": {
          backgroundColor: "#D9B441", // Hover background color
        },
      }}
    >
      <Container maxWidth="lg">
        <Typography variant="body2" align="center">
          © {new Date().getFullYear()} Equations Work. All Rights Reserved.
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;
