import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Box,
} from "@mui/material";
import axios from "axios";
import { useAuth } from "../AuthContext";
import SnackbarAlert from "./SnackbarAlert";

const AnalysisResult = ({ open, onClose, result, onSave }) => {
  const [editedResult, setEditedResult] = useState({});
  const [invoiceTypes, setInvoiceTypes] = useState([]);
  const { userID } = useAuth();
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  useEffect(() => {
    if (result) {
      const cleanResult = {
        ...result,
        tax_amount: result.tax_amount || "",
        total_amount: result.total_amount || "",
        discount_amount: result.discount_amount || "",
        transaction_time: result.transaction_time
          ? new Date(result.transaction_time).toISOString().slice(0, 16)
          : "",
        invoice_type_id: result.invoice_type ? result.invoice_type.id : "",
        invoice_type: result.invoice_type || null,
        merchant_name: result.merchant_name || "",
        invoice_category: result.invoice_category || "",
        bill_no: result.bill_no || "",
        email: result.email || "",
        mobile_number: result.mobile_number || "",
        address: result.address || "",
        gst_number: result.gst_number || "",
        purchase_summary: result.purchase_summary || "",
        item_names: result.item_names || "",
      };

      console.log("Setting edited result:", cleanResult);
      setEditedResult(cleanResult);
    } else {
      setEditedResult({});
    }
  }, [result]);

  useEffect(() => {
    if (open) {
      fetchInvoiceTypes();
    }
  }, [open, userID]);

  const fetchInvoiceTypes = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/get_invoice_types?user_id=${userID}`
      );
      setInvoiceTypes(response.data);
    } catch (error) {
      console.error("Error fetching invoice types:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log("Handling change:", name, value);

    if (name === "invoice_type_id") {
      const selectedType = invoiceTypes.find((type) => type.id === value);
      setEditedResult((prev) => ({
        ...prev,
        invoice_type_id: value,
        invoice_type: selectedType,
      }));
    } else {
      setEditedResult((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const renderInvoiceTypeOptions = () => {
    return invoiceTypes.map((type) => (
      <MenuItem key={type.id} value={type.id} disabled={!type.is_active}>
        {type.name}
        <Typography variant="caption" color="textSecondary" sx={{ ml: 1 }}>
          {type.creator_id === parseInt(userID)
            ? " (Created by you)"
            : ` (Created by ${type.creator_fullname || "Unknown"})`}
        </Typography>
      </MenuItem>
    ));
  };

  const handleSave = () => {
    const dataToSave = {
      ...editedResult,
      invoice_type_id: editedResult.invoice_type_id || null,
    };

    if (dataToSave.invoice_type_id) {
      const selectedType = invoiceTypes.find(
        (type) => type.id === dataToSave.invoice_type_id
      );
      dataToSave.invoice_type = selectedType;
    }

    onSave(dataToSave);
    setSnackbarOpen(true);
    onClose();
  };

  const fieldGroups = [
    {
      title: "Basic Information",
      fields: [
        "merchant_name",
        "total_amount",
        "transaction_time",
        "invoice_category",
      ],
    },
    {
      title: "Invoice Details",
      fields: [
        "invoice_type_id",
        "bill_no",
        "purchase_summary",
        "item_names",
        "tax_amount",
        "discount_amount",
      ],
    },
    {
      title: "Contact Information",
      fields: ["email", "mobile_number", "address", "gst_number"],
    },
  ];

  const getFieldType = (field) => {
    if (field.includes("amount") || field.includes("tax")) return "number";
    if (field.includes("time")) return "datetime-local";
    return "text";
  };

  const isCreator = result?.user_id === parseInt(userID);

  if (!editedResult) return null;

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
        <DialogTitle>
          {result?.id
            ? isCreator
              ? "Edit Transaction"
              : "View Transaction"
            : "New Transaction"}
        </DialogTitle>
        <DialogContent>
          {fieldGroups.map((group) => (
            <Box key={group.title} sx={{ mb: 3 }}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                {group.title}
              </Typography>
              {group.fields.map((field) => {
                if (field === "invoice_type_id") {
                  return (
                    <FormControl fullWidth margin="normal" key={field}>
                      <InputLabel>Invoice Type</InputLabel>
                      <Select
                        name="invoice_type_id"
                        value={editedResult.invoice_type_id || ""}
                        onChange={handleChange}
                        label="Invoice Type"
                        disabled={!isCreator && result?.id}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {renderInvoiceTypeOptions()}
                      </Select>
                    </FormControl>
                  );
                }

                return (
                  <TextField
                    key={field}
                    fullWidth
                    margin="normal"
                    name={field}
                    label={field.replace(/_/g, " ").toUpperCase()}
                    value={editedResult[field] || ""}
                    onChange={handleChange}
                    multiline={
                      field === "purchase_summary" || field === "address"
                    }
                    type={getFieldType(field)}
                    disabled={!isCreator && result?.id}
                  />
                );
              })}
            </Box>
          ))}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onClose}
            sx={{
              backgroundColor: "#FFE392", // Default background color
              "&:hover": {
                backgroundColor: "#D9B441", // Hover background color
              },
              textTransform: "capitalize",
              color: "black", // Text color
              fontFamily: "Proxima-Nova-Font, sans-serif",
            }}
          >
            Cancel
          </Button>
          {(isCreator || !result?.id) && (
            <Button
              onClick={handleSave}
              sx={{
                backgroundColor: "#FFE392", // Default background color
                "&:hover": {
                  backgroundColor: "#D9B441", // Hover background color
                },
                textTransform: "capitalize",
                color: "black", // Text color
                fontFamily: "Proxima-Nova-Font, sans-serif",
              }}
            >
              Save
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <SnackbarAlert
        snackbarOpen={snackbarOpen}
        snackbarMessage="Transaction saved successfully"
        snackbarSeverity="success"
        handleSnackbarClose={() => setSnackbarOpen(false)}
      />
    </>
  );
};

export default AnalysisResult;
