import axios from "axios";

const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || "http://localhost:5000/api";

const handleApiError = (error, errorMessage) => {
  console.error(errorMessage, error);
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    console.error("Response data:", error.response.data);
    console.error("Response status:", error.response.status);
    console.error("Response headers:", error.response.headers);
  } else if (error.request) {
    // The request was made but no response was received
    console.error("No response received:", error.request);
  } else {
    // Something happened in setting up the request that triggered an Error
    console.error("Error setting up request:", error.message);
  }
  throw error;
};

export const createJob = async (userID) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/add_job`, {
      user_id: userID,
    });
    return response.data.job_id;
  } catch (error) {
    handleApiError(error, "Error creating job:");
  }
};

export const updateJobWithImage = async (jobId, imageData) => {
  try {
    await axios.put(`${API_BASE_URL}/update_job/${jobId}`, {
      invoice_image: imageData,
    });
  } catch (error) {
    handleApiError(error, "Error updating job with image:");
  }
};

export const saveTransaction = async (jobId, transactionData) => {
  try {
    await axios.post(`${API_BASE_URL}/add_transaction`, {
      job_id: jobId,
      ...transactionData,
    });
  } catch (error) {
    handleApiError(error, "Error saving transaction:");
  }
};
