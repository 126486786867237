import React, { useCallback, useRef, useState, useEffect } from "react";
import Box from "@mui/material/Box";
import MuiCard from "@mui/material/Card";
import { styled } from "@mui/material/styles";
import SnackbarAlert from "./SnackbarAlert";
import logo from "../assets/BillWeaver.png";
import captureexp from "../assets/CaptureExpense.png";
import chatexpense from "../assets/ChatExpense.png";
import expenseinsights from "../assets/ExpenseInsights.png";
import expenselog from "../assets/ExpenseLog.png";
import premiumupgrade from "../assets/PremiumUpgrade.png";
import settings from "../assets/Settings.png";
import { Typography } from "@mui/material";
import { useCurrency } from "../CurrencyContext";
import { useAuth } from "../AuthContext";
import axios from "axios";
import WebcamCapture from "./WebcamCapture";
import AnalysisResult from "./AnalysisResult";
import { callGeminiAPI } from "./geminiAPI";
import { createJob, updateJobWithImage, saveTransaction } from "../api/jobApi";
const Card = styled(MuiCard)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignSelf: "center",
  width: "100%",
  maxWidth: "300px",
  height: "auto",
  padding: "24px",
  margin: "auto",
  // backgroundColor: "#1a237e",
  backgroundColor: "#fff",
  borderRadius: "16px",
  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.25)",
  [theme.breakpoints.up("sm")]: {
    maxWidth: "400px",
  },
}));

const ExpenseContainer = styled(Box)(({ theme }) => ({
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: "16px",
  background: "linear-gradient(135deg, #1a237e 0%, #0d47a1 100%)",
  [theme.breakpoints.up("sm")]: {
    padding: "32px",
  },
}));

const Logo = styled("img")({
  width: "80px",
  height: "80px",
  marginBottom: "0px", // Reduced margin to decrease space
});

export default function Expense(onCapture, createJob) {
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");

  const [analysisResult, setAnalysisResult] = useState(null);
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [showAnalysisResult, setShowAnalysisResult] = useState(false);
  const [currentJobId, setCurrentJobId] = useState(null);
  const [userSummary, setUserSummary] = useState({
    total_expense: 0,
    transaction_count: 0,
  });
  const [refreshTransactions, setRefreshTransactions] = useState(false);

  const [open, setOpen] = useState(false);
  const [capturedImage, setCapturedImage] = useState(null);
  const [crop, setCrop] = useState({ unit: "%", width: 30, aspect: 4 / 3 });
  const [completedCrop, setCompletedCrop] = useState(null);
  const [cropMode, setCropMode] = useState(false);
  const [isWebcam, setIsWebcam] = useState(true);
  const [jobId, setJobId] = useState(null);
  const [rotation, setRotation] = useState(0);
  const [facingMode, setFacingMode] = useState("environment");
  const [isMobile, setIsMobile] = useState(false);
  const webcamRef = useRef(null);
  const imgRef = useRef(null);
  const fileInputRef = useRef(null);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const { userID } = useAuth();
  //const theme = useTheme();

  const { currency } = useCurrency();

  const fetchUserSummary = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/get_user_summary/${userID}`
      );
      setUserSummary(response.data);
    } catch (error) {
      console.error("Error fetching user summary:", error);
      //console.error("Error fetching user summary:", error);
      if (error.response) {
        setSnackbarMessage(
          error.response.data.message || "Error fetching user summary."
        );
      } else {
        setSnackbarMessage("An error occurred. Please try again.");
      }
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return false;
    }
  };

  const handleCreateJob = useCallback(async () => {
    try {
      alert(userID);
      const jobId = await createJob(userID);
      setCurrentJobId(jobId);
      return jobId;
    } catch (error) {
      console.error("Error creating job:", error);
    }
  }, [userID]);

  const handleCapture = useCallback(async (imageData, jobId) => {
    setIsAnalyzing(true);
    try {
      await updateJobWithImage(jobId, imageData);
      const result = await callGeminiAPI(imageData);
      setAnalysisResult(result);
      setShowAnalysisResult(true);
    } catch (error) {
      console.error("Error analyzing image:", error);
    } finally {
      setIsAnalyzing(false);
    }
  }, []);

  const handleSaveAnalysis = useCallback(
    async (analysisData) => {
      try {
        if (!currentJobId) {
          throw new Error("No current job ID");
        }

        const requiredFields = [
          "username",
          "email",
          "mobile_number",
          "merchant_name",
          "bill_no",
          "item_names",
          "purchase_summary",
          "tax_amount",
          "total_amount",
          "gst_number",
          "address",
          "transaction_time",
          "discount_amount",
          "invoice_category",
          "invoice_type",
        ];

        const dataToSave = { ...analysisData };
        requiredFields.forEach((field) => {
          if (dataToSave[field] === undefined) {
            dataToSave[field] = null;
          }
        });

        // New code to handle item_names
        if (Array.isArray(dataToSave.item_names)) {
          dataToSave.item_names = dataToSave.item_names.join(", "); // Join array into a comma-separated string
        }

        await saveTransaction(currentJobId, {
          user_id: userID,
          ...dataToSave,
        });

        setShowAnalysisResult(false);
        setRefreshTransactions((prev) => !prev);
        fetchUserSummary();
      } catch (error) {
        console.error("Error saving transaction:", error);
      }
    },
    [currentJobId, userID]
  );

  const handleCloseAnalysisResult = () => {
    setShowAnalysisResult(false);
  };

  useEffect(() => {
    fetchUserSummary();
  }, [userID]);

  useEffect(() => {
    const checkMobile = () => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      setIsMobile(
        /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
          userAgent.toLowerCase()
        )
      );
    };
    checkMobile();
  }, []);

  const videoConstraints = {
    width: 720,
    height: 480,
    facingMode: facingMode,
  };

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setCapturedImage(imageSrc);
    setCropMode(true);
  }, [webcamRef]);

  const handleOpen = useCallback(
    async (mode) => {
      setOpen(true);
      setIsWebcam(mode === "webcam");
      const newJobId = await createJob();
      setJobId(newJobId);
      if (mode === "upload") {
        fileInputRef.current.click();
      }
    },
    [createJob]
  );

  const handleClose = () => {
    setOpen(false);
    setCapturedImage(null);
    setCropMode(false);
  };

  const handleSave = useCallback(async () => {
    if (completedCrop && imgRef.current) {
      const croppedImageData = await getCroppedImg(
        imgRef.current,
        completedCrop,
        "cropped.jpeg"
      );

      if (onCapture && jobId) {
        const base64Data = croppedImageData.split(",")[1];
        onCapture(base64Data, jobId);
      }

      handleClose();
    }
  }, [completedCrop, onCapture, jobId]);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setCapturedImage(e.target.result);
        setCropMode(true);
      };
      reader.readAsDataURL(file);
    }
  };

  function getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve) => {
      canvas.toBlob(
        (blob) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.readAsDataURL(blob);
        },
        "image/jpeg",
        1
      );
    });
  }

  const rotateImage = () => {
    setRotation((prevRotation) => (prevRotation + 90) % 360);
  };

  const switchCamera = () => {
    setFacingMode((prevMode) => (prevMode === "user" ? "environment" : "user"));
  };

  return (
    <ExpenseContainer>
      <Card variant="outlined">
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            mb: 0, // Reduced margin bottom to decrease space
          }}
        >
          <Box>
            <Typography variant="h6" color="white">
              {currency}
              {userSummary.total_expense.toFixed(2)}
            </Typography>
            <Typography variant="body2" color="white">
              Bills woven till date
            </Typography>
          </Box>
          <Logo src={logo} alt="BillWeaver Logo" />
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            gap: 0, // Reduced gap to decrease space
          }}
        >
          <Logo
            src={captureexp}
            alt="Capture Expense"
            style={{ width: "150px", height: "150px" }}
            onClick={() => handleOpen("upload")}
            createJob={handleCreateJob}
          />

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexWrap: "wrap",
              gap: 2,
            }}
          >
            <Logo src={expenselog} alt="Expense Log" />
            <Logo src={expenseinsights} alt="Expense Insights" />
            <Logo src={chatexpense} alt="Chat Expense" />
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexWrap: "wrap",
              gap: 2,
            }}
          >
            <Logo src={expenseinsights} alt="Expense Insights" />
            <Logo src={premiumupgrade} alt="Premium Upgrade" />
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexWrap: "wrap",
              gap: 2,
            }}
          >
            <Logo src={settings} alt="Settings" />
          </Box>
        </Box>
      </Card>

      <SnackbarAlert
        snackbarOpen={snackbarOpen}
        snackbarMessage={snackbarMessage}
        snackbarSeverity={snackbarSeverity}
        handleSnackbarClose={handleSnackbarClose}
      />
    </ExpenseContainer>
  );
}
