import React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import WarningAmberIcon from "@mui/icons-material/WarningAmber"; // Import specific icons

// Alert Component with forwarding ref (required for Snackbar)
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SnackbarAlert = ({
  snackbarOpen,
  snackbarMessage,
  snackbarSeverity,
  handleSnackbarClose,
}) => {
  const getBackgroundColor = (severity) => {
    switch (severity) {
      case "success":
        return "#4caf50"; // Green for success
      case "error":
        return "#f44336"; // Red for error
      default:
        return "#1976d2"; // Light blue for other cases
    }
  };

  return (
    <Snackbar
      open={snackbarOpen}
      autoHideDuration={5000} // Changed to 5000ms (5 seconds)
      onClose={handleSnackbarClose}
      anchorOrigin={{ vertical: "top", horizontal: "right" }} // Centered position
    >
      <Alert
        onClose={handleSnackbarClose}
        severity={snackbarSeverity}
        sx={{
          width: "100%",
          backgroundColor: getBackgroundColor(snackbarSeverity),
          color: "#fff", // White text for all severities
          "& .MuiAlert-icon": {
            color: "#fff", // White icon color
          },
        }}
        iconMapping={{
          warning: <WarningAmberIcon sx={{ color: "#fff" }} />, // White icon for warning
        }}
        action={
          <IconButton
            aria-label="close"
            onClick={handleSnackbarClose}
            sx={{
              width: "15px", // Adjust the width
              height: "15px", // Adjust the height
              padding: "5px", // Remove padding for a tighter fit
              marginTop: "0px",
              marginBottom: "10px",
              marginLeft: "10px",
              color: "#fff", // White close icon
              border: "none", // Remove square border
              borderRadius: "0", // Remove circular border
              boxShadow: "none", // Remove any shadow if present
              backgroundColor: "transparent", // Transparent background
            }}
          >
            <CloseIcon sx={{ fontSize: "20px" }} /> {/* Adjust icon size */}
          </IconButton>
        }
      >
        {snackbarMessage}
      </Alert>
    </Snackbar>
  );
};

export default SnackbarAlert;
