import React, { useState } from "react";
import MyTransactionsOld from "./MyTransactions_old";
import MyTransactionsNew from "./MyTransactions_new";
import {
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { ViewList, GridView } from "@mui/icons-material";

const MyTransactions = ({ userID, refreshTrigger }) => {
  // Get the saved view preference from localStorage, default to 'grid'
  const [viewMode, setViewMode] = useState(
    localStorage.getItem("transactionViewMode") || "grid"
  );

  const handleViewChange = (event, newView) => {
    if (newView !== null) {
      setViewMode(newView);
      // Save the preference to localStorage
      localStorage.setItem("transactionViewMode", newView);
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: "#3f51b5",
        minHeight: "100vh",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          mb: 2,
          mt: 2,
          mr: 2,
          gap: 1,
        }}
      >
        <Typography variant="body2" color="textSecondary">
          View Mode:
        </Typography>
        <ToggleButtonGroup
          value={viewMode}
          exclusive
          onChange={handleViewChange}
          aria-label="view mode"
          size="small"
        >
          <ToggleButton value="grid" aria-label="grid view">
            <GridView />
          </ToggleButton>
          <ToggleButton value="table" aria-label="table view">
            <ViewList />
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>

      {viewMode === "table" ? (
        <MyTransactionsOld userID={userID} refreshTrigger={refreshTrigger} />
      ) : (
        <MyTransactionsNew userID={userID} refreshTrigger={refreshTrigger} />
      )}
    </Box>
  );
};

export default MyTransactions;
