// PrivateRoute.js
import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "./AuthContext";

// PrivateRoute component to protect routes that require authentication
const PrivateRoute = ({ children }) => {
  const { userID } = useAuth();
  const location = useLocation();

  if (userID === null) {
    // Redirect to the login page, but save the current location
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
};

export default PrivateRoute;
