// import React, { useCallback, useRef, useState, useEffect } from "react";
// import Webcam from "react-webcam";
// import ReactCrop from "react-image-crop";
// import "react-image-crop/dist/ReactCrop.css";
// import CloudUploadIcon from "@mui/icons-material/CloudUpload";

// import {
//   Button,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   Grid,
//   Box,
//   IconButton,
// } from "@mui/material";
// import CloseIcon from "@mui/icons-material/Close";
// import FlipCameraIosIcon from "@mui/icons-material/FlipCameraIos";
// import CameraIcon from "../assets/CaptureExpense.png";

// const WebcamCapture = ({ onCapture, createJob }) => {
//   const [open, setOpen] = useState(false);
//   const [capturedImage, setCapturedImage] = useState(null);
//   const [crop, setCrop] = useState({ unit: "%", width: 30, aspect: 4 / 3 });
//   const [completedCrop, setCompletedCrop] = useState(null);
//   const [cropMode, setCropMode] = useState(false);
//   const [isWebcam, setIsWebcam] = useState(true);
//   const [jobId, setJobId] = useState(null);
//   const [rotation, setRotation] = useState(0);
//   const [facingMode, setFacingMode] = useState("environment");
//   const [isMobile, setIsMobile] = useState(false);
//   const webcamRef = useRef(null);
//   const imgRef = useRef(null);
//   const fileInputRef = useRef(null);

//   useEffect(() => {
//     const checkMobile = () => {
//       const userAgent = navigator.userAgent || navigator.vendor || window.opera;
//       setIsMobile(
//         /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
//           userAgent.toLowerCase()
//         )
//       );
//     };
//     checkMobile();
//   }, []);

//   const videoConstraints = {
//     width: 720,
//     height: 480,
//     facingMode: facingMode,
//   };

//   const capture = useCallback(() => {
//     const imageSrc = webcamRef.current.getScreenshot();
//     setCapturedImage(imageSrc);
//     setCropMode(true);
//   }, [webcamRef]);

//   const handleOpen = useCallback(
//     async (mode) => {
//       setOpen(true);
//       setIsWebcam(mode === "webcam");
//       const newJobId = await createJob();
//       setJobId(newJobId);
//       if (mode === "upload") {
//         fileInputRef.current.click();
//       }
//     },
//     [createJob]
//   );

//   const handleClose = () => {
//     setOpen(false);
//     setCapturedImage(null);
//     setCropMode(false);
//   };

//   const handleSave = useCallback(async () => {
//     if (completedCrop && imgRef.current) {
//       const croppedImageData = await getCroppedImg(
//         imgRef.current,
//         completedCrop,
//         "cropped.jpeg"
//       );

//       if (onCapture && jobId) {
//         const base64Data = croppedImageData.split(",")[1];
//         onCapture(base64Data, jobId);
//       }

//       handleClose();
//     }
//   }, [completedCrop, onCapture, jobId]);

//   const handleFileUpload = (event) => {
//     const file = event.target.files[0];
//     if (file) {
//       const reader = new FileReader();
//       reader.onload = (e) => {
//         setCapturedImage(e.target.result);
//         setCropMode(true);
//       };
//       reader.readAsDataURL(file);
//     }
//   };

//   function getCroppedImg(image, crop, fileName) {
//     const canvas = document.createElement("canvas");
//     const scaleX = image.naturalWidth / image.width;
//     const scaleY = image.naturalHeight / image.height;
//     canvas.width = crop.width;
//     canvas.height = crop.height;
//     const ctx = canvas.getContext("2d");

//     ctx.drawImage(
//       image,
//       crop.x * scaleX,
//       crop.y * scaleY,
//       crop.width * scaleX,
//       crop.height * scaleY,
//       0,
//       0,
//       crop.width,
//       crop.height
//     );

//     return new Promise((resolve) => {
//       canvas.toBlob(
//         (blob) => {
//           const reader = new FileReader();
//           reader.onloadend = () => resolve(reader.result);
//           reader.readAsDataURL(blob);
//         },
//         "image/jpeg",
//         1
//       );
//     });
//   }

//   const rotateImage = () => {
//     setRotation((prevRotation) => (prevRotation + 90) % 360);
//   };

//   const switchCamera = () => {
//     setFacingMode((prevMode) => (prevMode === "user" ? "environment" : "user"));
//   };

//   return (
//     <>
//       <Grid container spacing={2} justifyContent="center" marginTop="1rem">
//         <Grid item>
//           <Button
//             // variant="contained"
//             // color="primary"
//             onClick={() => handleOpen("webcam")}
//             sx={{
//               // padding: "8px",
//               minWidth: "unset", // This removes the minimum width constraint
//             }}
//           >
//             <img
//               src={CameraIcon}
//               alt="Capture Expense"
//               style={{
//                 width: "10rem",
//                 height: "10rem",
//                 // objectFit: "contain",
//               }}
//             />
//           </Button>
//         </Grid>
//         <Grid item>
//           {/* <Button
//             variant="contained"
//             color="primary"
//             onClick={() => handleOpen("upload")}
//           >
//             Upload Invoice
//           </Button> */}
//         </Grid>
//       </Grid>
//       <input
//         type="file"
//         accept="image/*"
//         style={{ display: "none" }}
//         ref={fileInputRef}
//         onChange={handleFileUpload}
//       />
//       <Dialog
//         open={open}
//         onClose={handleClose}
//         maxWidth="md"
//         fullWidth
//         PaperProps={{
//           style: {
//             margin: 0,
//             maxWidth: "100%",
//             height: "100%",
//             maxHeight: "none",
//             borderRadius: 0,
//           },
//         }}
//       >
//         <IconButton
//           aria-label="close"
//           onClick={handleClose}
//           sx={{
//             position: "absolute",
//             right: 8,
//             top: 8,
//             color: (theme) => theme.palette.grey[500],
//             zIndex: 1,
//           }}
//         >
//           <CloseIcon />
//         </IconButton>
//         <DialogContent
//           style={{
//             padding: 0,
//             position: "relative",
//             height: "100%",
//             width: "100%",
//             overflow: "hidden",
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//             backgroundColor: "gray",
//           }}
//         >
//           {!capturedImage ? (
//             isWebcam ? (
//               <>
//                 <Webcam
//                   audio={false}
//                   ref={webcamRef}
//                   screenshotFormat="image/jpeg"
//                   height="100%"
//                   width="100%"
//                   videoConstraints={videoConstraints}
//                   style={{ objectFit: "contain" }}
//                 />
//                 <Box
//                   sx={{
//                     position: "absolute",
//                     bottom: 16,
//                     left: "50%",
//                     transform: "translateX(-50%)",
//                     display: "flex",
//                     gap: 2,
//                   }}
//                 >
//                   <Button
//                     variant="contained"
//                     component="label"
//                     // style={{ backgroundColor: "rgba(255, 255, 255, 0.7)" }}

//                     sx={{
//                       fontWeight: "bold",
//                       fontSize: "1rem",
//                       padding: "12px",
//                       textTransform: "capitalize",
//                       backgroundColor: "#FFE392",
//                       fontFamily: "Proxima-Nova-Font, sans-serif",
//                       color: "black",
//                       "&:hover": {
//                         backgroundColor: "#D9B441", // Hover background color
//                       },
//                     }}
//                   >
//                     <Box sx={{ display: "flex", alignItems: "center" }}>
//                       <CloudUploadIcon sx={{ mr: 1 }} />{" "}
//                       {/* Add margin to the right */}
//                       Upload from Device
//                     </Box>
//                     <input
//                       type="file"
//                       hidden
//                       accept="image/*"
//                       onChange={handleFileUpload}
//                     />
//                   </Button>
//                 </Box>
//                 {isMobile && (
//                   <IconButton
//                     onClick={switchCamera}
//                     style={{
//                       position: "absolute",
//                       bottom: 16,
//                       right: 16,
//                       backgroundColor: "rgba(255, 255, 255, 0.7)",
//                     }}
//                   >
//                     <FlipCameraIosIcon />
//                   </IconButton>
//                 )}
//               </>
//             ) : null
//           ) : cropMode ? (
//             <Box
//               sx={{
//                 height: "100%",
//                 width: "100%",
//                 overflow: "auto",
//                 display: "flex",
//                 justifyContent: "center",
//                 alignItems: "center",
//               }}
//             >
//               <ReactCrop
//                 src={capturedImage}
//                 crop={crop}
//                 onChange={(newCrop) => setCrop(newCrop)}
//                 onComplete={(c) => setCompletedCrop(c)}
//                 style={{ maxHeight: "100%", maxWidth: "100%" }}
//               >
//                 <img
//                   ref={imgRef}
//                   src={capturedImage}
//                   alt="Captured"
//                   style={{
//                     transform: `rotate(${rotation}deg)`,
//                     maxWidth: "100%",
//                     maxHeight: "100%",
//                     objectFit: "contain",
//                   }}
//                 />
//               </ReactCrop>
//             </Box>
//           ) : (
//             <Box
//               sx={{
//                 height: "100%",
//                 width: "100%",
//                 overflow: "auto",
//                 display: "flex",
//                 justifyContent: "center",
//                 alignItems: "center",
//               }}
//             >
//               <img
//                 src={capturedImage}
//                 alt="captured"
//                 style={{
//                   maxWidth: "100%",
//                   maxHeight: "100%",
//                   objectFit: "contain",
//                   transform: `rotate(${rotation}deg)`,
//                 }}
//               />
//             </Box>
//           )}
//         </DialogContent>
//         <DialogActions style={{ justifyContent: "center" }}>
//           {!capturedImage ? (
//             isWebcam && (
//               <>
//                 <Button
//                   onClick={handleClose}
//                   color="secondary"
//                   variant="contained"
//                   sx={{
//                     fontWeight: "bold",
//                     fontSize: "1rem",
//                     //padding: "8px",
//                     textTransform: "capitalize",
//                     backgroundColor: "#FFE392",
//                     fontFamily: "Proxima-Nova-Font, sans-serif",
//                     color: "black",
//                     "&:hover": {
//                       backgroundColor: "#D9B441", // Hover background color
//                     },
//                   }}
//                 >
//                   Close
//                 </Button>
//                 <Button
//                   onClick={capture}
//                   color="primary"
//                   variant="contained"
//                   sx={{
//                     fontWeight: "bold",
//                     fontSize: "1rem",
//                     // padding: "10px",
//                     textTransform: "capitalize",
//                     backgroundColor: "#FFE392",
//                     fontFamily: "Proxima-Nova-Font, sans-serif",
//                     color: "black",
//                     "&:hover": {
//                       backgroundColor: "#D9B441", // Hover background color
//                     },
//                   }}
//                 >
//                   Capture
//                 </Button>
//               </>
//             )
//           ) : (
//             <>
//               <Button
//                 onClick={handleClose}
//                 color="secondary"
//                 variant="contained"
//               >
//                 Close
//               </Button>

//               <Button onClick={rotateImage} color="primary" variant="contained">
//                 Rotate
//               </Button>
//               <Button onClick={handleSave} color="primary" variant="contained">
//                 Save
//               </Button>
//             </>
//           )}
//         </DialogActions>
//       </Dialog>
//     </>
//   );
// };

// export default WebcamCapture;

import React, { useCallback, useRef, useState, useEffect } from "react";
import Webcam from "react-webcam";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Box,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FlipCameraIosIcon from "@mui/icons-material/FlipCameraIos";
import CameraIcon from "../assets/CaptureExpense.png";

const WebcamCapture = ({ onCapture, createJob }) => {
  const [open, setOpen] = useState(false);
  const [capturedImage, setCapturedImage] = useState(null);
  const [crop, setCrop] = useState({ unit: "%", width: 30, aspect: 4 / 3 });
  const [completedCrop, setCompletedCrop] = useState(null);
  const [cropMode, setCropMode] = useState(false);
  const [isWebcam, setIsWebcam] = useState(true);
  const [jobId, setJobId] = useState(null);
  const [rotation, setRotation] = useState(0);
  const [facingMode, setFacingMode] = useState("environment");
  const [isMobile, setIsMobile] = useState(false);
  const webcamRef = useRef(null);
  const imgRef = useRef(null);
  const fileInputRef = useRef(null);

  useEffect(() => {
    const checkMobile = () => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      setIsMobile(
        /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
          userAgent.toLowerCase()
        )
      );
    };
    checkMobile();
  }, []);

  const videoConstraints = {
    width: 720,
    height: 480,
    facingMode: facingMode,
  };

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setCapturedImage(imageSrc);
    setCropMode(true);
  }, [webcamRef]);

  const handleOpen = useCallback(
    async (mode) => {
      setOpen(true);
      setIsWebcam(mode === "webcam");
      const newJobId = await createJob();
      setJobId(newJobId);
      if (mode === "upload") {
        fileInputRef.current.click();
      }
    },
    [createJob]
  );

  const handleClose = () => {
    setOpen(false);
    setCapturedImage(null);
    setCropMode(false);
  };

  const handleSave = useCallback(async () => {
    if (completedCrop && imgRef.current) {
      const croppedImageData = await getCroppedImg(
        imgRef.current,
        completedCrop,
        "cropped.jpeg"
      );

      if (onCapture && jobId) {
        const base64Data = croppedImageData.split(",")[1];
        onCapture(base64Data, jobId);
      }

      handleClose();
    }
  }, [completedCrop, onCapture, jobId]);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setCapturedImage(e.target.result);
        setCropMode(true);
      };
      reader.readAsDataURL(file);
    }
  };

  function getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve) => {
      canvas.toBlob(
        (blob) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.readAsDataURL(blob);
        },
        "image/jpeg",
        1
      );
    });
  }

  const rotateImage = () => {
    setRotation((prevRotation) => (prevRotation + 90) % 360);
  };

  const switchCamera = () => {
    setFacingMode((prevMode) => (prevMode === "user" ? "environment" : "user"));
  };

  return (
    <>
      <Grid container spacing={2} justifyContent="center" marginTop="1rem">
        <Grid item>
          <Button
            // variant="contained"
            // color="primary"
            onClick={() => handleOpen("webcam")}
            sx={{
              // padding: "8px",
              minWidth: "unset", // This removes the minimum width constraint
            }}
          >
            <img
              src={CameraIcon}
              alt="Capture Expense"
              style={{
                width: "10rem",
                height: "10rem",
                // objectFit: "contain",
              }}
            />
          </Button>
        </Grid>
        <Grid item>
          {/* <Button
            variant="contained"
            color="primary"
            onClick={() => handleOpen("upload")}
          >
            Upload Invoice
          </Button> */}
        </Grid>
      </Grid>
      <input
        type="file"
        accept="image/*"
        style={{ display: "none" }}
        ref={fileInputRef}
        onChange={handleFileUpload}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        PaperProps={{
          style: {
            margin: 0,
            maxWidth: "100%",
            height: "100%",
            maxHeight: "none",
            borderRadius: 0,
          },
        }}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
            zIndex: 1,
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent
          style={{
            padding: 0,
            position: "relative",
            height: "100%",
            width: "100%",
            overflow: "hidden",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "gray",
          }}
        >
          {!capturedImage ? (
            isWebcam ? (
              <>
                <Webcam
                  audio={false}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  height="100%"
                  width="100%"
                  videoConstraints={videoConstraints}
                  style={{ objectFit: "contain" }}
                />
                <Box
                  sx={{
                    position: "absolute",
                    bottom: 16,
                    left: "50%",
                    transform: "translateX(-50%)",
                    display: "flex",
                    gap: 2,
                  }}
                >
                  <Button
                    variant="contained"
                    component="label"
                    sx={{
                      backgroundColor: "#FFE392", // Default background color
                      textTransform: "capitalize",
                      color: "black", // Text color
                      fontFamily: "Proxima-Nova-Font, sans-serif",
                      "&:hover": {
                        backgroundColor: "#D9B441", // Hover background color
                      },
                    }}
                  >
                    <CloudUploadIcon sx={{ mr: 1 }} />
                    Upload from Device
                    <input
                      type="file"
                      hidden
                      accept="image/*"
                      onChange={handleFileUpload}
                    />
                  </Button>
                </Box>
                {isMobile && (
                  <IconButton
                    onClick={switchCamera}
                    style={{
                      position: "absolute",
                      bottom: 16,
                      right: 16,
                      backgroundColor: "rgba(255, 255, 255, 0.7)",
                    }}
                  >
                    <FlipCameraIosIcon />
                  </IconButton>
                )}
              </>
            ) : null
          ) : cropMode ? (
            <Box
              sx={{
                height: "100%",
                width: "100%",
                overflow: "auto",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ReactCrop
                src={capturedImage}
                crop={crop}
                onChange={(newCrop) => setCrop(newCrop)}
                onComplete={(c) => setCompletedCrop(c)}
                style={{ maxHeight: "100%", maxWidth: "100%" }}
              >
                <img
                  ref={imgRef}
                  src={capturedImage}
                  alt="Captured"
                  style={{
                    transform: `rotate(${rotation}deg)`,
                    maxWidth: "100%",
                    maxHeight: "100%",
                    objectFit: "contain",
                  }}
                />
              </ReactCrop>
            </Box>
          ) : (
            <Box
              sx={{
                height: "100%",
                width: "100%",
                overflow: "auto",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={capturedImage}
                alt="captured"
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                  objectFit: "contain",
                  transform: `rotate(${rotation}deg)`,
                }}
              />
            </Box>
          )}
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          {!capturedImage ? (
            isWebcam && (
              <>
                <Button
                  onClick={handleClose}
                  color="secondary"
                  variant="contained"
                  sx={{
                    backgroundColor: "#FFE392", // Default background color
                    "&:hover": {
                      backgroundColor: "#D9B441", // Hover background color
                    },
                    textTransform: "capitalize",
                    color: "black", // Text color
                    fontFamily: "Proxima-Nova-Font, sans-serif",
                  }}
                >
                  Close
                </Button>
                <Button
                  onClick={capture}
                  color="primary"
                  variant="contained"
                  sx={{
                    backgroundColor: "#FFE392", // Default background color
                    "&:hover": {
                      backgroundColor: "#D9B441", // Hover background color
                    },
                    textTransform: "capitalize",
                    color: "black", // Text color
                    fontFamily: "Proxima-Nova-Font, sans-serif",
                  }}
                >
                  Capture
                </Button>
              </>
            )
          ) : (
            <>
              <Button
                onClick={handleClose}
                color="secondary"
                variant="contained"
                sx={{
                  backgroundColor: "#FFE392", // Default background color
                  "&:hover": {
                    backgroundColor: "#D9B441", // Hover background color
                  },
                  textTransform: "capitalize",
                  color: "black", // Text color
                  fontFamily: "Proxima-Nova-Font, sans-serif",
                }}
              >
                Close
              </Button>

              <Button
                onClick={rotateImage}
                color="primary"
                variant="contained"
                sx={{
                  backgroundColor: "#FFE392", // Default background color
                  "&:hover": {
                    backgroundColor: "#D9B441", // Hover background color
                  },
                  textTransform: "capitalize",
                  color: "black", // Text color
                  fontFamily: "Proxima-Nova-Font, sans-serif",
                }}
              >
                Rotate
              </Button>
              <Button
                onClick={handleSave}
                color="primary"
                variant="contained"
                sx={{
                  backgroundColor: "#FFE392", // Default background color
                  "&:hover": {
                    backgroundColor: "#D9B441", // Hover background color
                  },
                  textTransform: "capitalize",
                  color: "black", // Text color
                  fontFamily: "Proxima-Nova-Font, sans-serif",
                }}
              >
                Save
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default WebcamCapture;
