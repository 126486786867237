import React, { useState, useEffect } from "react";
import { BarChart, axisClasses } from "@mui/x-charts";
import { PieChart } from "@mui/x-charts/PieChart";
import {
  Typography,
  Paper,
  Grid,
  Button,
  Box,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { PictureAsPdf, Close, Visibility } from "@mui/icons-material";
import axios from "axios";
import { styled } from "@mui/material/styles";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import html2canvas from "html2canvas";
import { useAuth } from "../AuthContext";
import { useCurrency } from "../CurrencyContext";
import { useNavigate } from "react-router-dom";
import SnackbarAlert from "../components/SnackbarAlert";

const StyledButton = styled(Button)(({ theme }) => ({
  // backgroundColor: theme.palette.primary.main,
  backgroundColor: "#FFE392",
  color: "black",
  padding: "10px 20px",
  borderRadius: "5px",
  textTransform: "none",
  fontWeight: "bold",
  fontFamily: "Proxima-Nova-Font, sans-serif",
  "&:hover": {
    backgroundColor: "#D9B441",
  },
}));

const Report = () => {
  const [transactions, setTransactions] = useState([]);
  const [barChartData, setBarChartData] = useState([]);
  const [pieChartData, setPieChartData] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openImageDialog, setOpenImageDialog] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [username, setUsername] = useState("");
  const [totalExpense, setTotalExpense] = useState(0);
  const [totalTransactions, setTotalTransactions] = useState(0);

  // Snackbar state
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");

  const navigate = useNavigate();

  const { userID, logout } = useAuth();
  const { currency } = useCurrency();

  const handleLogout = () => {
    logout();

    navigate("/"); // Redirect to login after logging out
  };

  useEffect(() => {
    if (userID) {
      fetchTransactions();
      fetchUserData();
      fetchUserSummary();
    }
  }, [userID]);

  const fetchTransactions = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/get_user_transactions/${userID}`
      );
      setTransactions(response.data);
      processChartData(response.data);
    } catch (error) {
      console.error("Error fetching transactions:", error);
      setSnackbarMessage("Error fetching transactions data");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return false;
    }
  };

  const processChartData = (data) => {
    // Process data for BarChart (transactions per day)
    const transactionsPerDay = data.reduce((acc, transaction) => {
      const date = new Date(transaction.transaction_time)
        .toISOString()
        .split("T")[0];
      acc[date] = (acc[date] || 0) + 1;
      return acc;
    }, {});

    const barData = Object.entries(transactionsPerDay)
      .sort(([dateA], [dateB]) => new Date(dateA) - new Date(dateB))
      .map(([date, count]) => ({
        date: date,
        count: count,
      }));

    setBarChartData(barData);

    // Process data for PieChart (category-wise transactions)
    const categoryCount = data.reduce((acc, transaction) => {
      acc[transaction.invoice_category] =
        (acc[transaction.invoice_category] || 0) + 1;
      return acc;
    }, {});

    const pieData = Object.entries(categoryCount).map(
      ([category, count], index) => ({
        id: index,
        value: count,
        label: category,
      })
    );

    setPieChartData(pieData);
  };

  const handleViewImage = async (jobId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/get_job_image/${jobId}`
      );
      if (response.data && response.data.image) {
        setSelectedImage(`data:image/jpeg;base64,${response.data.image}`);
        setOpenImageDialog(true);
      } else {
        console.error("No image data received");
        setSnackbarMessage("No image data received");
      }
    } catch (error) {
      console.error("Error fetching job image:", error);
      setSnackbarMessage("Error fetching job image");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return false;
    }
  };

  const generatePDF = async () => {
    const doc = new jsPDF();
    let yOffset = 20;

    // Add a Unicode font to support ₹ symbol
    doc.addFont(
      "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
      "Roboto",
      "normal"
    );
    doc.setFont("Roboto");

    // Add title and user information
    doc.setFontSize(24);
    doc.setTextColor(0, 102, 204);
    doc.text("Expense Report", doc.internal.pageSize.getWidth() / 2, yOffset, {
      align: "center",
    });
    yOffset += 15;

    doc.setFontSize(14);
    doc.setTextColor(0, 0, 0);
    doc.text(`User: ${username || "N/A"}`, 14, yOffset);
    yOffset += 10;

    // Format the total expense with commas and two decimal places
    const formattedTotalExpense = totalExpense.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    // Use the currency symbol from the context, ensuring it's encoded correctly
    const currencySymbol = currency === "₹" ? "\u20B9" : currency;
    doc.text(
      `Total Expense: ${currencySymbol}${formattedTotalExpense}`,
      14,
      yOffset
    );
    yOffset += 10;
    doc.text(`Total Transactions: ${totalTransactions || 0}`, 14, yOffset);
    yOffset += 20;

    // Add bar chart
    doc.setFontSize(16);
    doc.setTextColor(0, 102, 204);
    doc.text("Transactions per Day", 14, yOffset);
    yOffset += 10;

    const barChartElement = document.getElementById("bar-chart");
    if (barChartElement) {
      const barChartCanvas = await html2canvas(barChartElement);
      const barChartImage = barChartCanvas.toDataURL("image/png");
      doc.addImage(barChartImage, "PNG", 14, yOffset, 180, 100);
      yOffset += 110;
    }

    // Add pie chart
    doc.setFontSize(16);
    doc.setTextColor(0, 102, 204);
    doc.text("Category-wise Transactions", 14, yOffset);
    yOffset += 10;

    const pieChartElement = document.getElementById("pie-chart");
    if (pieChartElement) {
      const pieChartCanvas = await html2canvas(pieChartElement);
      const pieChartImage = pieChartCanvas.toDataURL("image/png");
      doc.addImage(pieChartImage, "PNG", 14, yOffset - 5, 180, 100);
      yOffset += 90;
    }

    // Add transaction details
    doc.addPage();
    yOffset = 20;
    doc.setFontSize(16);
    doc.setTextColor(0, 102, 204);
    doc.text("Transaction Details", 14, yOffset);
    yOffset += 10;

    const tableData = transactions.map((t) => {
      // Format currency values with the symbol
      const formatCurrency = (value) => {
        if (value === null || value === undefined)
          return `${currencySymbol}0.00`;
        return `${currencySymbol}${parseFloat(value).toFixed(2)}`;
      };

      return [
        t.merchant_name,
        t.bill_no,
        formatCurrency(t.total_amount), // Format with currency symbol
        formatCurrency(t.tax_amount || 0), // Format with currency symbol
        formatCurrency(t.discount_amount || 0), // Format with currency symbol
        new Date(t.transaction_time).toLocaleDateString(),
        t.invoice_category,
        t.gst_number,
        t.address,
      ];
    });

    doc.autoTable({
      startY: yOffset,
      head: [
        [
          "Merchant",
          "Bill No",
          "Total",
          "Tax",
          "Discount",
          "Date",
          "Category",
          "GST No",
          "Address",
        ],
      ],
      body: tableData,
      theme: "striped",
      headStyles: { fillColor: [0, 102, 204], textColor: 255 },
      alternateRowStyles: { fillColor: [240, 240, 240] },
      styles: {
        font: "Roboto", // Ensure using the Unicode-compatible font
        fontSize: 10,
      },
      didDrawPage: (data) => {
        yOffset = data.cursor.y + 10;
      },
    });

    // Only add a new page if there's content to add
    if (transactions.length > 0) {
      // Group transactions by date
      const groupedTransactions = transactions.reduce((acc, t) => {
        const date = new Date(t.transaction_time).toLocaleDateString();
        if (!acc[date]) acc[date] = [];
        acc[date].push(t);
        return acc;
      }, {});

      // Add invoice images
      for (const dateTransactions of Object.values(groupedTransactions)) {
        doc.addPage();
        yOffset = 20;

        for (const transaction of dateTransactions) {
          try {
            const response = await axios.get(
              `${process.env.REACT_APP_API_BASE_URL}/get_job_image/${transaction.job_id}`
            );
            if (response.data && response.data.image) {
              const img = new Image();
              img.src = `data:image/jpeg;base64,${response.data.image}`;
              await new Promise((resolve) => {
                img.onload = resolve;
              });
              const imgWidth = doc.internal.pageSize.getWidth() - 28;
              const imgHeight = (img.height * imgWidth) / img.width;

              if (
                yOffset + imgHeight >
                doc.internal.pageSize.getHeight() - 20
              ) {
                doc.addPage();
                yOffset = 20;
              }

              doc.addImage(img, "JPEG", 14, yOffset, imgWidth, imgHeight);
              yOffset += imgHeight + 10;
            }
          } catch (error) {
            console.error("Error fetching job image:", error);
            setSnackbarMessage("Error fetching job image");
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
            return false;
          }
        }
      }
    }

    // Add page numbers
    const pageCount = doc.internal.getNumberOfPages();
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.setFontSize(10);
      doc.setTextColor(150);
      doc.text(
        `Page ${i} of ${pageCount}`,
        doc.internal.pageSize.getWidth() - 20,
        doc.internal.pageSize.getHeight() - 10
      );
    }

    return doc.output("datauristring");
  };

  const handlePreviewReport = async () => {
    const pdfDataUrl = await generatePDF();
    setPdfUrl(pdfDataUrl);
    setOpenDialog(true);
  };

  const handleExportPDF = async () => {
    const pdfDataUrl = await generatePDF();
    const link = document.createElement("a");
    link.href = pdfDataUrl;
    link.download = "expense_report.pdf";
    link.click();
  };

  const fetchUserData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/user/${userID}`
      );
      setUsername(response.data.fullname);
    } catch (error) {
      console.error("Error fetching user data:", error);
      setSnackbarMessage("Error fetching user data");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return false;
    }
  };

  const fetchUserSummary = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/get_user_summary/${userID}`
      );
      setTotalExpense(parseFloat(response.data.total_expense)); // Ensure this is a number
      setTotalTransactions(response.data.transaction_count);
    } catch (error) {
      console.error("Error fetching user summary:", error);
      setSnackbarMessage("Error fetching user summary");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return false;
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        p: 3,
        pt: 2,
        backgroundColor: "#3f51b5",
        height: "calc(100vh - 128px)", // Added this line
        overflow: "auto", // Add this to enable scrolling if content is too long
      }}
    >
      <Typography
        variant="h5"
        gutterBottom
        color="white"
        sx={{
          textAlign: "center",
          mb: 2, // Added margin bottom for better spacing
          fontFamily: "Proxima-Nova-Font, sans-serif",
        }}
      >
        Expense Report
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>
              Transactions per Day
            </Typography>
            <div id="bar-chart">
              <Box sx={{ position: "relative", height: 285 }}>
                <BarChart
                  xAxis={[
                    {
                      scaleType: "band",
                      data: barChartData.map((item) => item.date),
                      valueFormatter: (date) =>
                        new Date(date).toLocaleDateString(),
                    },
                  ]}
                  yAxis={[
                    {
                      scaleType: "linear",
                      title: "Number of Transactions",
                    },
                  ]}
                  series={[
                    {
                      data: barChartData.map((item) => item.count),
                      label: "Transactions",
                    },
                  ]}
                  height={300}
                  margin={{ left: 70, bottom: 70, right: 20, top: 50 }}
                  sx={{
                    [`.${axisClasses.left} .${axisClasses.label}`]: {
                      transform: "rotate(-90deg) translate(0px, -20px)",
                    },
                    [`.${axisClasses.bottom} .${axisClasses.label}`]: {
                      transform: "rotate(-45deg) translate(-20px, 0px)",
                    },
                  }}
                  slotProps={{
                    legend: {
                      hidden: true, // Hide the default legend
                    },
                  }}
                />
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <Box
                    sx={{
                      width: 16,
                      height: 16,
                      backgroundColor: "rgb(0, 200, 200)",
                    }}
                  />
                  <Typography variant="body2">Transactions</Typography>
                </Box>
              </Box>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Category-wise Transactions
            </Typography>
            <Box
              id="pie-chart"
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                "& > div": {
                  // Target the PieChart's container
                  margin: "0 auto",
                },
              }}
            >
              <PieChart
                series={[
                  {
                    data: pieChartData,
                    highlightScope: { faded: "global", highlighted: "item" },
                    faded: {
                      innerRadius: 30,
                      additionalRadius: -30,
                      color: "gray",
                    },
                  },
                ]}
                height={300}
                width={300} // Add fixed width to ensure proper centering
                slotProps={{
                  legend: {
                    direction: "row",
                    position: { vertical: "bottom", horizontal: "middle" },
                    padding: 20,
                  },
                }}
              />
            </Box>
          </Paper>
        </Grid>
      </Grid>

      <Box sx={{ mt: 3, display: "flex", justifyContent: "center", gap: 2 }}>
        <StyledButton onClick={handleExportPDF} startIcon={<PictureAsPdf />}>
          Download Report
        </StyledButton>
        {/* <StyledButton
          onClick={handlePreviewReport}
          startIcon={<Visibility />}
        >
          Preview Report
        </StyledButton> */}
      </Box>

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>
          Report Preview
          <IconButton
            aria-label="close"
            onClick={() => setOpenDialog(false)}
            sx={{ position: "absolute", right: 8, top: 8 }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {pdfUrl && (
            <iframe
              src={pdfUrl}
              width="100%"
              height="600px"
              style={{ border: "none" }}
            />
          )}
        </DialogContent>
        <DialogActions>
          <StyledButton onClick={handleExportPDF} startIcon={<PictureAsPdf />}>
            Download PDF
          </StyledButton>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openImageDialog}
        onClose={() => setOpenImageDialog(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Invoice Image</DialogTitle>
        <DialogContent>
          {selectedImage ? (
            <img
              src={selectedImage}
              alt="Invoice"
              style={{ width: "100%", height: "auto" }}
            />
          ) : (
            <Typography>No image available</Typography>
          )}
        </DialogContent>
      </Dialog>
      {/* Snackbar for success or error alerts */}
      <SnackbarAlert
        snackbarOpen={snackbarOpen}
        snackbarMessage={snackbarMessage}
        snackbarSeverity={snackbarSeverity}
        handleSnackbarClose={handleSnackbarClose}
      />
    </Box>
  );
};

export default Report;
