import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import logo from "../assets/BillWeaver.png";

const SplashContainer = styled(Box)({
  height: "100vh",
  width: "100vw",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#003C8E",
  position: "fixed",
  top: 0,
  left: 0,
  zIndex: 9999,
});

const Logo = styled("img")(({ isAnimating }) => ({
  width: "100px",
  height: "100px",
  transition: "transform 3s ease-in-out",
  transform: isAnimating ? "scale(1.5)" : "scale(1)",
}));

const SplashScreen = ({ onAnimationComplete }) => {
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    setIsAnimating(true);
    const timer = setTimeout(() => {
      setIsAnimating(false);
      setTimeout(() => {
        onAnimationComplete();
      }, 200);
    }, 3000);

    return () => clearTimeout(timer);
  }, [onAnimationComplete]);

  return (
    <SplashContainer>
      <Logo src={logo} alt="BillWeaver Logo" isAnimating={isAnimating} />
    </SplashContainer>
  );
};

export default SplashScreen;
