import React from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Box,
  Tooltip,
  useMediaQuery,
  Menu,
  MenuItem,
} from "@mui/material";
import {
  Description,
  ReceiptLong,
  AccountCircle,
  Logout,
  Settings,
  LightMode,
  DarkMode,
  Menu as MenuIcon,
} from "@mui/icons-material";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { useAuth } from "../AuthContext";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";

const Navbar = ({ toggleColorMode }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const { logout } = useAuth();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleLogout = () => {
    logout();
    navigate("/");
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const iconStyle = (path) => ({
    color: "inherit",
    borderBottom: location.pathname === path ? "2px solid white" : "none",
    borderRadius: 0,
    padding: "8px",
  });

  const renderMenuItems = () => (
    <>
      <MenuItem component={Link} to="/ET" onClick={handleMenuClose}>
        Home
      </MenuItem>
      <MenuItem component={Link} to="/Mytransactions" onClick={handleMenuClose}>
        Expense Log
      </MenuItem>
      <MenuItem component={Link} to="/report" onClick={handleMenuClose}>
        Expense Insights
      </MenuItem>
      <MenuItem component={Link} to="/user-info" onClick={handleMenuClose}>
        Settings
      </MenuItem>
      <MenuItem component={Link} to="/settings" onClick={handleMenuClose}>
        Ledger Management
      </MenuItem>
      <MenuItem
        onClick={() => {
          toggleColorMode();
          handleMenuClose();
        }}
      >
        {theme.palette.mode === "dark" ? "Light Mode" : "Dark Mode"}
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleLogout();
          handleMenuClose();
        }}
      >
        Logout
      </MenuItem>
    </>
  );

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: "#FFE392",
        color: "black",
        "&:hover": {
          backgroundColor: "#D9B441",
        },
      }}
    >
      <Toolbar
        sx={{
          height: "64px",
          minHeight: "64px",
          justifyContent: "space-between",
          px: { xs: 1, sm: 2 },
          fontFamily: "Proxima-Nova-Font, sans-serif",
        }}
      >
        <Typography
          variant="h6"
          component="div"
          noWrap
          sx={{
            fontFamily: "Proxima-Nova-Font, sans-serif",
            marginLeft: "0.7rem",
          }}
        >
          Billweaver
        </Typography>
        {isMobile ? (
          <>
            <IconButton
              color="inherit"
              aria-label="menu"
              onClick={handleMenuOpen}
              edge="end"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              {renderMenuItems()}
            </Menu>
          </>
        ) : (
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Tooltip title="Home">
                <IconButton
                  component={Link}
                  to="/ET"
                  sx={{
                    ...iconStyle("/ET"),
                    color: "black",
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.1)",
                    },
                  }}
                  size="large"
                >
                  <ReceiptLong />
                </IconButton>
              </Tooltip>
              <Tooltip title="My Transactions">
                <IconButton
                  component={Link}
                  to="/Mytransactions"
                  sx={{
                    ...iconStyle("/Mytransactions"),
                    color: "black",
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.1)",
                    },
                  }}
                  size="large"
                >
                  <AccountBalanceWalletIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Report">
                <IconButton
                  component={Link}
                  to="/report"
                  sx={{
                    ...iconStyle("/report"),
                    color: "black",
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.1)",
                    },
                  }}
                  size="large"
                >
                  <Description />
                </IconButton>
              </Tooltip>
              <Tooltip title="User Info">
                <IconButton
                  component={Link}
                  to="/user-info"
                  sx={{
                    ...iconStyle("/user-info"),
                    color: "black",
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.1)",
                    },
                  }}
                  size="large"
                >
                  <AccountCircle />
                </IconButton>
              </Tooltip>
              <Tooltip title="Settings">
                <IconButton
                  component={Link}
                  to="/settings"
                  sx={{
                    ...iconStyle("/settings"),
                    color: "black",
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.1)",
                    },
                  }}
                  size="large"
                >
                  <Settings />
                </IconButton>
              </Tooltip>

              <Tooltip
                title={`Switch to ${
                  theme.palette.mode === "dark" ? "Light" : "Dark"
                } Mode`}
              >
                <IconButton
                  onClick={toggleColorMode}
                  color="inherit"
                  size="large"
                >
                  {theme.palette.mode === "dark" ? <LightMode /> : <DarkMode />}
                </IconButton>
              </Tooltip>
              <Tooltip title="Logout">
                <IconButton onClick={handleLogout} color="inherit" size="large">
                  <Logout />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
