import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import SnackbarAlert from "../components/SnackbarAlert";

function ForgotPassword({ open, handleClose }) {
  const [email, setEmail] = React.useState("");
  const [otp, setOtp] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [step, setStep] = React.useState(1);
  const [loading, setLoading] = React.useState(false);

  // Add this new function to reset the state
  const resetState = () => {
    setEmail("");
    setOtp("");
    setNewPassword("");
    setStep(1);
    setLoading(false);
  };

  // Snackbar state
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");

  // Modify the handleClose function
  const handleDialogClose = () => {
    resetState();
    handleClose();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      if (step === 1) {
        await axios.post(`${process.env.REACT_APP_API_BASE_URL}/send_otp`, {
          email,
        });
        setStep(2);
      } else if (step === 2) {
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/verify_otp`,
          { email, otp }
        );
        if (response.data.verified) {
          setStep(3);
        } else {
          setSnackbarMessage("Incorrect OTP. Please try again");
          setSnackbarSeverity("error");
          setSnackbarOpen(true);
        }
      } else if (step === 3) {
        await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/reset_password`,
          {
            email,
            new_password: newPassword,
          }
        );
        setSnackbarMessage("Password reset successfully.");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
        handleClose();
      }
    } catch (error) {
      setSnackbarMessage("An error occurred. Please try again.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      handleClose();
    } finally {
      setLoading(false);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleDialogClose}>
      <DialogTitle>Reset Password</DialogTitle>
      <DialogContent>
        {step === 1 && (
          <>
            <DialogContentText>
              Enter your account's email address to receive an OTP.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="email"
              // label="Email Address"
              placeholder="Email Address"
              type="email"
              fullWidth
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </>
        )}
        {step === 2 && (
          <>
            <DialogContentText>
              Enter the OTP sent to your email.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="otp"
              // label="OTP"
              placeholder="OTP"
              type="text"
              fullWidth
              variant="outlined"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
            />
          </>
        )}
        {step === 3 && (
          <>
            <DialogContentText>Enter your new password.</DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="new-password"
              // label="New Password"
              placeholder="New Password"
              type="password"
              fullWidth
              variant="outlined"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialogClose}>Cancel</Button>
        <Button onClick={handleSubmit} disabled={loading}>
          {loading ? (
            <CircularProgress size={24} />
          ) : step === 1 ? (
            "Send OTP"
          ) : step === 2 ? (
            "Verify OTP"
          ) : (
            "Reset Password"
          )}
        </Button>
      </DialogActions>

      {/* Snackbar for success or error alerts */}
      <SnackbarAlert
        snackbarOpen={snackbarOpen}
        snackbarMessage={snackbarMessage}
        snackbarSeverity={snackbarSeverity}
        handleSnackbarClose={handleSnackbarClose}
      />
    </Dialog>
  );
}

ForgotPassword.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default ForgotPassword;
