import React, { useState, useEffect } from "react";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import {
  Typography,
  Paper,
  Box,
  Switch,
  FormControlLabel,
  TextField,
  Button,
  Snackbar,
  CircularProgress,
} from "@mui/material";
import { Edit, Save } from "@mui/icons-material";
import axios from "axios";
import { useAuth } from "../AuthContext";

import { useNavigate } from "react-router-dom";
import SnackbarAlert from "../components/SnackbarAlert";

const UserInfo = () => {
  const [userInfo, setUserInfo] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedInfo, setEditedInfo] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const navigate = useNavigate();

  const { userID, logout } = useAuth();

  useEffect(() => {
    if (userID) {
      fetchUserInfo();
    }
  }, [userID]);

  const handleLogout = () => {
    logout();

    navigate("/"); // Redirect to login after logging out
  };

  const fetchUserInfo = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/user/${userID}`
      );
      setUserInfo(response.data);
      setEditedInfo(response.data);
    } catch (error) {
      console.error("Error fetching user info:", error);
      setSnackbarMessage("Error fetching user information");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateUser = async () => {
    setIsLoading(true);
    try {
      const updatedFields = {};
      if (editedInfo.fullname !== userInfo.fullname) {
        updatedFields.fullname = editedInfo.fullname;
      }
      if (editedInfo.email !== userInfo.email) {
        updatedFields.email = editedInfo.email;
      }
      if (editedInfo.receive_updates !== userInfo.receive_updates) {
        updatedFields.receive_updates = editedInfo.receive_updates;
      }

      if (Object.keys(updatedFields).length > 0) {
        await axios.put(
          `${process.env.REACT_APP_API_BASE_URL}/user/${userID}`,
          updatedFields
        );
        setUserInfo(editedInfo);
        setSnackbarMessage("User information updated successfully");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
      }
      setIsEditing(false);
    } catch (error) {
      console.error("Error updating user info:", error);
      setSnackbarMessage("Error updating user info");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEditedInfo((prev) => ({ ...prev, [name]: value }));
  };

  const handleSwitchChange = (event) => {
    setEditedInfo((prev) => ({
      ...prev,
      receive_updates: event.target.checked,
    }));
  };

  const renderContent = () => {
    if (isLoading) {
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "80vh",
          }}
        >
          <CircularProgress />
        </Box>
      );
    }

    if (!userInfo) {
      return <Typography>User information not available.</Typography>;
    }

    return (
      <Paper sx={{ p: 3, maxWidth: 600, margin: "auto" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Typography
            variant="h4"
            gutterBottom
            sx={{ fontFamily: "Proxima-Nova-Font, sans-serif" }}
          >
            User Information
          </Typography>
          <Button
            startIcon={isEditing ? <Save /> : <Edit />}
            onClick={() =>
              isEditing ? handleUpdateUser() : setIsEditing(true)
            }
            sx={{
              fontFamily: "Proxima-Nova-Font, sans-serif", // Set your custom font family
            }}
          >
            {isEditing ? "Save" : "Edit"}
          </Button>
        </Box>
        <Box sx={{ mb: 2 }}>
          <Box sx={{ display: "flex", flexDirection: "column", mb: 2 }}>
            <FormControl>
              <FormLabel
                htmlFor="Full Name"
                sx={{
                  color: "black",
                  fontFamily: "Proxima-Nova-Font, sans-serif",
                }}
              >
                Full Name
              </FormLabel>
              <TextField
                fullWidth
                name="fullname"
                value={editedInfo.fullname || ""}
                onChange={handleInputChange}
                disabled={!isEditing}
                margin="normal"
                InputProps={{
                  sx: {
                    fontFamily: "Proxima-Nova-Font, sans-serif",
                  },
                }}
              />
            </FormControl>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", mb: 2 }}>
            <FormControl>
              <FormLabel
                htmlFor="Email"
                sx={{
                  color: "black",
                  fontFamily: "Proxima-Nova-Font, sans-serif",
                }}
              >
                Email
              </FormLabel>
              <TextField
                fullWidth
                name="email"
                value={editedInfo.email || ""}
                onChange={handleInputChange}
                disabled={!isEditing}
                margin="normal"
                InputProps={{
                  sx: {
                    fontFamily: "Proxima-Nova-Font, sans-serif",
                  },
                }}
              />
            </FormControl>
          </Box>
        </Box>
        <FormControlLabel
          control={
            <Switch
              checked={editedInfo.receive_updates}
              onChange={handleSwitchChange}
              name="receive_updates"
              disabled={!isEditing}
            />
          }
          label="Receive Updates"
          sx={{
            "& .MuiFormControlLabel-label": {
              fontFamily: "Proxima-Nova-Font, sans-serif",
              color: "black", // Optional: Set the text color
            },
          }}
        />
      </Paper>
    );
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        p: 3,
        backgroundColor: "#3f51b5",
        minHeight: "100vh", // Ensures background covers full height
      }}
    >
      <Typography
        variant="h5"
        gutterBottom
        color="white"
        sx={{
          textAlign: "center",
          mb: 3,
          fontFamily: "Proxima-Nova-Font, sans-serif",
        }}
      >
        {/* User Information */}
      </Typography>
      {renderContent()}
      <SnackbarAlert
        snackbarOpen={snackbarOpen}
        snackbarMessage={snackbarMessage}
        snackbarSeverity={snackbarSeverity}
        handleSnackbarClose={handleSnackbarClose}
      />
    </Box>
  );
};

export default UserInfo;
