import React, { useState, useEffect } from "react";
import "./App.css";
import SignIn from "./sign-in/SignIn";
import SignUp from "./sign-up/SignUp";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import ExpenseTracker from "./components/ExpenseTracker";
import Report from "./components/Report";
import SubscriptionPlans from "./components/Subscription";
import { AuthProvider, useAuth } from "./AuthContext";
import PrivateRoute from "./PrivateRoute";
import UserInfo from "./components/UserInfo";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer"; // Import the new Footer component
import { Box } from "@mui/material"; // Import Box for layout
import Settings from "./components/Settings";
import { CurrencyProvider } from "./CurrencyContext";
import Expense from "./components/Expense";
import MyTransactions from "./components/MyTransactions";
import SplashScreen from "./components/SplashScreen";

function AppContent() {
  const location = useLocation();
  const { userID } = useAuth();
  const [mode, setMode] = useState("light");
  const [showSplash, setShowSplash] = useState(true);

  useEffect(() => {
    const savedMode = localStorage.getItem("themeMode");
    if (savedMode) {
      setMode(savedMode);
    } else {
      const systemPrefersDark = window.matchMedia(
        "(prefers-color-scheme: dark)"
      ).matches;
      setMode(systemPrefersDark ? "dark" : "light");
    }
  }, []);

  const toggleColorMode = () => {
    const newMode = mode === "light" ? "dark" : "light";
    setMode(newMode);
    localStorage.setItem("themeMode", newMode);
  };

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode,
        },
      }),
    [mode]
  );

  const showNavbar =
    userID && !["/", "/signup", "/sub"].includes(location.pathname);

  // Add this new condition for the footer
  const showFooter = !["/", "/signup"].includes(location.pathname);

  if (showSplash) {
    return <SplashScreen onAnimationComplete={() => setShowSplash(false)} />;
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100vh",
          width: "100%",
          backgroundColor: "#f5f5f5",
          overflow: "hidden",
        }}
      >
        {showNavbar && <Navbar toggleColorMode={toggleColorMode} />}
        <Box
          sx={{
            flex: 1,
            width: "100%",
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Routes>
            {/* Public routes */}
            <Route path="/" element={<SignIn />} />
            <Route path="/signup" element={<SignUp />} />

            {/* Protected routes */}
            <Route
              path="/sub"
              element={
                <PrivateRoute>
                  <SubscriptionPlans />
                </PrivateRoute>
              }
            />
            <Route
              path="/et"
              element={
                <PrivateRoute>
                  <ExpenseTracker />
                </PrivateRoute>
              }
            />
            <Route
              path="/mytransactions"
              element={
                <PrivateRoute>
                  <MyTransactions userID={userID} refreshTrigger={true} />
                </PrivateRoute>
              }
            />
            <Route
              path="/expense"
              element={
                <PrivateRoute>
                  <Expense />
                </PrivateRoute>
              }
            />
            <Route
              path="/report"
              element={
                <PrivateRoute>
                  <Report />
                </PrivateRoute>
              }
            />
            <Route
              path="/user-info"
              element={
                <PrivateRoute>
                  <UserInfo />
                </PrivateRoute>
              }
            />
            <Route
              path="/settings"
              element={
                <PrivateRoute>
                  <Settings />
                </PrivateRoute>
              }
            />
          </Routes>
        </Box>
        {showFooter && <Footer />}
      </Box>
    </ThemeProvider>
  );
}

function App() {
  return (
    <AuthProvider>
      <CurrencyProvider>
        <Router>
          <AppContent />
        </Router>
      </CurrencyProvider>
    </AuthProvider>
  );
}

export default App;
