// AuthContext.js
import React, { createContext, useState, useContext, useEffect } from "react";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [userID, setUserID] = useState(() => {
    // Initialize userID from localStorage
    return localStorage.getItem("userID") || null;
  });

  useEffect(() => {
    // Update localStorage whenever userID changes
    if (userID) {
      localStorage.setItem("userID", userID);
    } else {
      localStorage.removeItem("userID");
    }
  }, [userID]);

  const login = (id) => {
    setUserID(id);
  };

  const logout = () => {
    setUserID(null);
  };

  return (
    <AuthContext.Provider value={{ userID, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
