import React, { useState, useCallback, useEffect } from "react";
import {
  Grid,
  Typography,
  Box,
  Dialog,
  DialogContent,
  CircularProgress,
  Button,
} from "@mui/material";
import axios from "axios";
import { useTheme } from "@mui/material/styles";
import { useCurrency } from "../CurrencyContext";
import WebcamCapture from "./WebcamCapture";
import AnalysisResult from "./AnalysisResult";
import { callGeminiAPI } from "./geminiAPI";
import { createJob, updateJobWithImage, saveTransaction } from "../api/jobApi";
import ExpenseInsights from "../assets/ExpenseInsights.png";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../AuthContext";
import SnackbarAlert from "../components/SnackbarAlert";
import CaptureExpense from "../assets/CaptureExpense.png";
import PremiumUpgrade from "../assets/PremiumUpgrade.png";
import LedgerManagement from "../assets/LedgerManagement.png";
import UserSettings from "../assets/Settings.png";
import MuiCard from "@mui/material/Card";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import logo from "../assets/BillWeaver.png";
import ExpenseLog from "../assets/ExpenseLog.png";

const Card = styled(MuiCard)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignSelf: "center",
  width: "100%",
  height: "calc(100vh - 128px)",
  padding: theme.spacing(2),
  gap: theme.spacing(1.5),
  margin: 0,
  backgroundColor: "#003C8E",
  boxShadow:
    "hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px",
  overflow: "auto",
  [theme.breakpoints.up("sm")]: {
    width: "450px",
    height: "auto",
    padding: theme.spacing(4),
    margin: "auto",
    gap: theme.spacing(2),
  },
  ...theme.applyStyles("light", {
    boxShadow:
      "hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px",
  }),
}));

const ExpenseTrackerContainer = styled(Stack)(({ theme }) => ({
  minHeight: "calc(100vh - 128px)",
  padding: 0,
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(4),
  },
  backgroundImage:
    "radial-gradient(ellipse at 50% 50%, hsl(210, 100%, 97%), hsl(0, 0%, 100%))",
  backgroundRepeat: "no-repeat",
  ...theme.applyStyles("dark", {
    backgroundImage:
      "radial-gradient(at 50% 50%, hsla(210, 100%, 16%, 0.5), hsl(220, 30%, 5%))",
  }),
}));

const Logo = styled("img")({
  width: "80px",
  height: "80px",
  marginBottom: "0px",
  "@media (min-width: 600px)": {
    width: "100px",
    height: "100px",
  },
});

const ExpenseTracker = () => {
  const [analysisResult, setAnalysisResult] = useState(null);
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [showAnalysisResult, setShowAnalysisResult] = useState(false);
  const [currentJobId, setCurrentJobId] = useState(null);
  const [userSummary, setUserSummary] = useState({
    total_expense: 0,
    transaction_count: 0,
  });
  const [refreshTransactions, setRefreshTransactions] = useState(false);

  // Snackbar state
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const { userID } = useAuth();
  const theme = useTheme();
  const { currency } = useCurrency();
  const navigate = useNavigate();

  const fetchUserSummary = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/get_user_summary/${userID}`
      );
      setUserSummary(response.data);
    } catch (error) {
      console.error("Error fetching user summary:", error);
      if (error.response) {
        setSnackbarMessage(
          error.response.data.message || "Error fetching user summary."
        );
      } else {
        setSnackbarMessage("An error occurred. Please try again.");
      }
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return false;
    }
  };

  const handleCreateJob = useCallback(async () => {
    try {
      const jobId = await createJob(userID);
      setCurrentJobId(jobId);
      return jobId;
    } catch (error) {
      console.error("Error creating job:", error);
    }
  }, [userID]);

  const handleCapture = useCallback(async (imageData, jobId) => {
    setIsAnalyzing(true);
    try {
      await updateJobWithImage(jobId, imageData);
      try {
        const result = await callGeminiAPI(imageData);
        setAnalysisResult(result);
        setShowAnalysisResult(true);
      } catch (error) {
        console.error("Error analyzing image:", error);
        setSnackbarMessage("Error analyzing image. Please try again.");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error("Error analyzing image:", error);
      setSnackbarMessage("Error analyzing image. Please try again.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setIsAnalyzing(false);
    }
  }, []);

  const handleSaveAnalysis = useCallback(
    async (analysisData) => {
      try {
        if (!currentJobId) {
          throw new Error("No current job ID");
        }

        const dataToSave = { ...analysisData };
        // If invoice_type is an object, extract its id
        if (
          typeof dataToSave.invoice_type === "object" &&
          dataToSave.invoice_type !== null
        ) {
          dataToSave.invoice_type = dataToSave.invoice_type.id;
        }

        await saveTransaction(currentJobId, {
          user_id: userID,
          ...dataToSave,
        });

        setShowAnalysisResult(false);
        setRefreshTransactions((prev) => !prev);
        fetchUserSummary();
      } catch (error) {
        console.error("Error saving transaction:", error);
      }
    },
    [currentJobId, userID]
  );

  const handleCloseAnalysisResult = () => {
    setShowAnalysisResult(false);
  };

  useEffect(() => {
    fetchUserSummary();
  }, [userID]);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const navigationButtons = [
    {
      image: CaptureExpense,
      alt: "Capture Expense",
      component: (
        <WebcamCapture
          userID={userID}
          onCapture={handleCapture}
          createJob={handleCreateJob}
        />
      ),
      size: {
        mobile: "10rem",
        desktop: "12rem",
      },
    },
    {
      image: ExpenseInsights,
      alt: "Expense Insights",
      onClick: () => navigate("/report"),
      size: {
        mobile: "6rem",
        desktop: "7rem",
      },
    },
    {
      image: PremiumUpgrade,
      alt: "Premium Upgrade",
      onClick: () => navigate("/sub"),
      size: {
        mobile: "6rem",
        desktop: "7rem",
      },
    },
    {
      image: LedgerManagement,
      alt: "Ledger Management",
      onClick: () => navigate("/settings"),
      size: {
        mobile: "4.8rem",
        desktop: "5.8rem",
      },
    },
    {
      image: UserSettings,
      alt: "User Settings",
      onClick: () => navigate("/user-info"),
      size: {
        mobile: "6rem",
        desktop: "7rem",
      },
    },
    {
      image: ExpenseLog,
      alt: "Expense Log",
      onClick: () => navigate("/mytransactions"),
      size: {
        mobile: "6rem",
        desktop: "7rem",
      },
    },
  ];

  return (
    <ExpenseTrackerContainer direction="column" justifyContent="space-between">
      <Card variant="outlined">
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 0,
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" color="white" sx={{ mr: 0 }}>
                {currency}
                {userSummary.total_expense.toFixed(2)}
              </Typography>
            </Box>
            <Typography variant="body2" color="white">
              Bills woven till date
            </Typography>
          </Box>
          <Logo src={logo} alt="BillWeaver Logo" />
        </Box>

        <Box sx={{ display: "flex", justifyContent: "center", mb: 2, mt: 0 }}>
          <Button
            sx={{
              minWidth: "unset",
              p: 0,
              "&:hover": {
                backgroundColor: "transparent",
                "& img": { transform: "scale(1.05)" },
              },
            }}
          >
            {navigationButtons[0].component}
          </Button>
        </Box>

        {/* First Line of Navigation Buttons */}
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
          sx={{ mb: 2 }}
        >
          {navigationButtons.slice(1, 4).map((button, index) => (
            <Grid
              item
              xs={4} // Ensures three buttons per row
              key={index}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Button
                onClick={button.onClick}
                sx={{
                  minWidth: "unset",
                  p: 0,
                  display: "flex",
                  alignItems: "center",
                  "&:hover": {
                    backgroundColor: "transparent",
                    "& img": { transform: "scale(1.05)" },
                  },
                }}
              >
                <img
                  src={button.image}
                  alt={button.alt}
                  style={{
                    width: theme.breakpoints.down("sm")
                      ? button.size.mobile
                      : button.size.desktop,
                    height: theme.breakpoints.down("sm")
                      ? button.size.mobile
                      : button.size.desktop,
                    transition: "transform 0.2s",
                    display: "block",
                  }}
                />
              </Button>
            </Grid>
          ))}
        </Grid>

        {/* Second Line of Navigation Buttons */}
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          {navigationButtons.slice(4).map((button, index) => (
            <Grid
              item
              xs={4} // Centers the two buttons under the gaps
              key={index}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Button
                onClick={button.onClick}
                sx={{
                  minWidth: "unset",
                  p: 0,
                  "&:hover": {
                    backgroundColor: "transparent",
                    "& img": { transform: "scale(1.05)" },
                  },
                }}
              >
                <img
                  src={button.image}
                  alt={button.alt}
                  style={{
                    width: theme.breakpoints.down("sm")
                      ? button.size.mobile
                      : button.size.desktop,
                    height: theme.breakpoints.down("sm")
                      ? button.size.mobile
                      : button.size.desktop,
                    transition: "transform 0.2s",
                  }}
                />
              </Button>
            </Grid>
          ))}
        </Grid>

        <SnackbarAlert
          snackbarOpen={snackbarOpen}
          snackbarMessage={snackbarMessage}
          snackbarSeverity={snackbarSeverity}
          handleSnackbarClose={handleSnackbarClose}
        />

        <AnalysisResult
          open={showAnalysisResult}
          onClose={handleCloseAnalysisResult}
          result={analysisResult}
          onSave={handleSaveAnalysis}
        />

        <Dialog
          open={isAnalyzing}
          PaperProps={{
            style: {
              backgroundColor: theme.palette.background.paper,
              boxShadow: theme.shadows[5],
              borderRadius: theme.shape.borderRadius,
              padding: theme.spacing(2),
            },
          }}
        >
          <DialogContent>
            <Box display="flex" flexDirection="column" alignItems="center">
              <CircularProgress
                size={60}
                thickness={4}
                style={{ color: theme.palette.primary.main }}
              />
              <Typography
                variant="h6"
                style={{
                  color: theme.palette.text.primary,
                  marginTop: theme.spacing(2),
                  fontWeight: 500,
                }}
              >
                Analyzing transaction...
              </Typography>
            </Box>
          </DialogContent>
        </Dialog>
      </Card>
    </ExpenseTrackerContainer>
  );
};

export default ExpenseTracker;
