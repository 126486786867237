import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Chip,
  Paper,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { Delete, Add, ContentCopy } from "@mui/icons-material";
import axios from "axios";
import { useAuth } from "../AuthContext";
import { useCurrency } from "../CurrencyContext";
import SnackbarAlert from "../components/SnackbarAlert";

const currencies = [
  { code: "USD", symbol: "$", name: "US Dollar" },
  { code: "EUR", symbol: "€", name: "Euro" },
  { code: "GBP", symbol: "£", name: "British Pound" },
  { code: "JPY", symbol: "¥", name: "Japanese Yen" },
  { code: "INR", symbol: "₹", name: "Indian Rupee" },
  { code: "CNY", symbol: "¥", name: "Chinese Yuan" },
  { code: "CAD", symbol: "C$", name: "Canadian Dollar" },
  { code: "AUD", symbol: "A$", name: "Australian Dollar" },
  { code: "CHF", symbol: "Fr", name: "Swiss Franc" },
  { code: "KRW", symbol: "₩", name: "South Korean Won" },
];

const Settings = () => {
  const { userID } = useAuth();
  const [defaultCurrency, setDefaultCurrency] = useState("USD");
  const [invoiceTypes, setInvoiceTypes] = useState([]);
  const [newInvoiceType, setNewInvoiceType] = useState("");
  const [newInvoiceTypeId, setNewInvoiceTypeId] = useState("");
  const { fetchCurrency } = useCurrency();
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedInvoiceType, setSelectedInvoiceType] = useState(null);

  // Snackbar state
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");

  useEffect(() => {
    fetchUserSettings();
    fetchInvoiceTypes();
  }, [userID]);

  const fetchUserSettings = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/user_settings/${userID}`
      );
      const currencySymbol = response.data.default_currency;
      const currency = currencies.find((c) => c.symbol === currencySymbol);
      setDefaultCurrency(currency ? currency.code : "USD");
      setInvoiceTypes(response.data.invoice_types);
    } catch (error) {
      //console.error("Error fetching user settings:", error);
      setSnackbarMessage("Error fetching user settings");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return false;
    }
  };

  const fetchInvoiceTypes = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/get_invoice_types?user_id=${userID}`
      );
      setInvoiceTypes(response.data);
    } catch (error) {
      console.error("Error fetching invoice types:", error);
      setSnackbarMessage("Error fetching invoice types");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleCurrencyChange = (event) => {
    setDefaultCurrency(event.target.value);
  };

  const handleAddInvoiceType = async () => {
    if (!newInvoiceType.trim()) {
      setSnackbarMessage("Invoice type name cannot be empty");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/create_invoice_type`,
        {
          user_id: userID,
          name: newInvoiceType,
        }
      );

      setInvoiceTypes((prevTypes) => [...prevTypes, response.data]);
      setNewInvoiceType("");
      setSnackbarMessage("Invoice type created successfully");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error creating invoice type:", error);
      setSnackbarMessage("Error creating invoice type");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleAddInvoiceTypeById = async () => {
    if (newInvoiceTypeId) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/add_invoice_type_by_id`,
          {
            user_id: userID,
            invoice_type_id: newInvoiceTypeId,
          }
        );
        setInvoiceTypes((prevTypes) => [
          ...prevTypes,
          response.data.invoice_type,
        ]);
        setNewInvoiceTypeId("");
        setSnackbarMessage("Invoice type added successfully");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
      } catch (error) {
        console.error("Error adding invoice type by ID:", error);
        setSnackbarMessage("Error adding invoice type");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    }
  };

  const handleRemoveInvoiceType = async (id) => {
    try {
      const invoiceType = invoiceTypes.find((type) => type.id === id);
      if (invoiceType.creator_id !== Number(userID)) {
        setSnackbarMessage(
          "You are not authorized to delete this invoice type"
        );
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        return;
      }

      await axios.delete(
        `${process.env.REACT_APP_API_BASE_URL}/delete_invoice_type/${id}?user_id=${userID}`
      );
      setInvoiceTypes(invoiceTypes.filter((type) => type.id !== id));
      setSnackbarMessage("Invoice type removed successfully");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error removing invoice type:", error);
      setSnackbarMessage(
        error.response?.data?.message || "Error removing invoice type"
      );
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleToggleInvoiceType = async (id) => {
    try {
      const invoiceType = invoiceTypes.find((type) => type.id === id);
      if (invoiceType.creator_id !== Number(userID)) {
        setSnackbarMessage(
          "You are not authorized to modify this invoice type"
        );
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        return;
      }

      const response = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/toggle_invoice_type/${id}`
      );
      setInvoiceTypes((prevTypes) =>
        prevTypes.map((type) =>
          type.id === id
            ? { ...type, is_active: response.data.is_active }
            : type
        )
      );
      setSnackbarMessage("Invoice type status updated");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error toggling invoice type:", error);
      setSnackbarMessage("Error updating invoice type status");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleCopyInvoiceTypeId = (id) => {
    navigator.clipboard.writeText(id);
    setSnackbarMessage("Invoice type ID copied to clipboard");
    setSnackbarSeverity("success");
    setSnackbarOpen(true);
  };

  const handleOpenDialog = (invoiceType) => {
    setSelectedInvoiceType(invoiceType);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleSaveSettings = async () => {
    try {
      await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/user_settings/${userID}`,
        {
          default_currency: currencies.find((c) => c.code === defaultCurrency)
            .symbol,
          invoice_types: invoiceTypes,
        }
      );
      //alert("Settings saved successfully");
      setSnackbarMessage("Settings saved successfully");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      fetchCurrency(); // Refetch the currency after saving
    } catch (error) {
      console.error("Error saving settings:", error);
      // alert("Error saving settings");
      setSnackbarMessage(
        "An error occurred in saving settings. Please try again."
      );
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return false;
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  // Add this function to fetch user details
  const getUserFullName = async (userId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/get_user_details/${userId}`
      );
      return response.data.fullname;
    } catch (error) {
      console.error("Error fetching user details:", error);
      return `User ${userId}`;
    }
  };

  return (
    <Box
      sx={{
        maxWidth: 600,
        margin: "auto",
        p: 3,
        backgroundColor: "#3f51b5",
        minHeight: "100vh", // This ensures the background covers the full height
      }}
    >
      <Typography
        variant="h5"
        color="white"
        gutterBottom
        sx={{
          mb: 2,
          fontFamily: "Proxima-Nova-Font, sans-serif",
        }}
      >
        Ledger and Currency Management
      </Typography>
      <Paper sx={{ p: 3 }}>
        <FormControl fullWidth sx={{ mb: 3 }}>
          <InputLabel>Default Currency</InputLabel>
          <Select
            value={defaultCurrency}
            onChange={handleCurrencyChange}
            label="Default Currency"
          >
            {currencies.map((currency) => (
              <MenuItem key={currency.code} value={currency.code}>
                {`${currency.symbol} - ${currency.name} (${currency.code})`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Typography
          variant="h6"
          gutterBottom
          sx={{ fontFamily: "Proxima-Nova-Font, sans-serif" }}
        >
          Invoice Types
        </Typography>
        <List>
          {invoiceTypes && invoiceTypes.length > 0 ? (
            invoiceTypes.map((invoiceType) => (
              <ListItem
                key={invoiceType.id}
                secondaryAction={
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <Chip
                      label={invoiceType.is_active ? "Enabled" : "Disabled"}
                      color={invoiceType.is_active ? "success" : "error"}
                      onClick={() => handleToggleInvoiceType(invoiceType.id)}
                      sx={{ mr: 1 }}
                      disabled={invoiceType.creator_id !== Number(userID)}
                    />
                    <IconButton
                      edge="end"
                      aria-label="copy"
                      onClick={() => handleCopyInvoiceTypeId(invoiceType.id)}
                    >
                      <ContentCopy />
                    </IconButton>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => handleRemoveInvoiceType(invoiceType.id)}
                      disabled={invoiceType.creator_id !== Number(userID)}
                    >
                      <Delete />
                    </IconButton>
                  </Box>
                }
              >
                <ListItemText
                  primary={invoiceType.name}
                  secondary={
                    invoiceType.creator_id === Number(userID)
                      ? "Created by you"
                      : `Created by ${invoiceType.creator_fullname}`
                  }
                  onClick={() => handleOpenDialog(invoiceType)}
                />
              </ListItem>
            ))
          ) : (
            <ListItem>
              <ListItemText primary="No invoice types available" />
            </ListItem>
          )}
        </List>
        <Box sx={{ display: "flex", gap: 1, mb: 3 }}>
          <TextField
            value={newInvoiceType}
            onChange={(e) => setNewInvoiceType(e.target.value)}
            label="New Invoice Type"
            variant="outlined"
            size="small"
          />
          <Button
            variant="contained"
            onClick={handleAddInvoiceType}
            sx={{
              backgroundColor: "#FFE392",
              width: "30%",
              fontFamily: "Proxima-Nova-Font, sans-serif",
              color: "black",
              textTransform: "capitalize",
              "&:hover": {
                backgroundColor: "#D9B441",
              },
            }}
          >
            Add
          </Button>
        </Box>
        <Box sx={{ display: "flex", gap: 1, mb: 3 }}>
          <TextField
            value={newInvoiceTypeId}
            onChange={(e) => setNewInvoiceTypeId(e.target.value)}
            label="Invoice Type ID"
            variant="outlined"
            size="small"
          />
          <Button
            variant="contained"
            onClick={handleAddInvoiceTypeById}
            sx={{
              backgroundColor: "#FFE392",
              fontFamily: "Proxima-Nova-Font, sans-serif",
              color: "black",
              textTransform: "capitalize",
              "&:hover": {
                backgroundColor: "#D9B441",
              },
            }}
          >
            Add by ID
          </Button>
        </Box>
        <Button
          variant="contained"
          onClick={handleSaveSettings}
          fullWidth
          sx={{
            backgroundColor: "#FFE392",
            fontFamily: "Proxima-Nova-Font, sans-serif",
            color: "black",
            textTransform: "capitalize",
            "&:hover": {
              backgroundColor: "#D9B441",
            },
          }}
        >
          Save Settings
        </Button>
      </Paper>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Invoice Type Details</DialogTitle>
        <DialogContent>
          {selectedInvoiceType && (
            <>
              <Typography>
                <strong>Name:</strong> {selectedInvoiceType.name}
              </Typography>
              <Typography>
                <strong>ID:</strong> {selectedInvoiceType.id}
              </Typography>
              <Typography>
                <strong>Created by:</strong>{" "}
                {selectedInvoiceType.creator_id === userID
                  ? "You"
                  : selectedInvoiceType.creator_fullname}
              </Typography>
              <Typography>
                <strong>Status:</strong>{" "}
                {selectedInvoiceType.is_active ? "Enabled" : "Disabled"}
              </Typography>
              <Typography>
                <strong>Created at:</strong>{" "}
                {new Date(selectedInvoiceType.created_at).toLocaleString()}
              </Typography>
              <Typography>
                <strong>Updated at:</strong>{" "}
                {new Date(selectedInvoiceType.updated_at).toLocaleString()}
              </Typography>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Close</Button>
        </DialogActions>
      </Dialog>

      <SnackbarAlert
        snackbarOpen={snackbarOpen}
        snackbarMessage={snackbarMessage}
        snackbarSeverity={snackbarSeverity}
        handleSnackbarClose={handleSnackbarClose}
      />
    </Box>
  );
};

export default Settings;
