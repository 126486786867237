import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import CssBaseline from "@mui/material/CssBaseline";
import FormControlLabel from "@mui/material/FormControlLabel";
import Divider from "@mui/material/Divider";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import MuiCard from "@mui/material/Card";
import { styled } from "@mui/material/styles";
import ForgotPassword from "./ForgotPassword";
import { GoogleIcon, FacebookIcon, SitemarkIcon } from "./CustomIcons";
import AppTheme from "../shared-theme/AppTheme";
import ColorModeSelect from "../shared-theme/ColorModeSelect";
import SignUp from "../sign-up/SignUp";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../AuthContext";
import SnackbarAlert from "../components/SnackbarAlert";
import logo from "../assets/BillWeaver.png";

const Logo = styled("img")({
  width: "100px",
  height: "100px",
  marginBottom: "16px",
  display: "block", // Ensures the image is treated as a block element
  marginLeft: "auto", // Centers the image horizontally
  marginRight: "auto", // Centers the image horizontally
});

const Card = styled(MuiCard)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignSelf: "center",
  width: "100%",
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  margin: "auto",
  backgroundColor: "#003C8E",
  [theme.breakpoints.up("sm")]: {
    maxWidth: "450px",
  },
  boxShadow:
    "hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px",
  ...theme.applyStyles("light", {
    boxShadow:
      "hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px",
  }),
}));

const SignInContainer = styled(Stack)(({ theme }) => ({
  minHeight: "100vh",
  justifyContent: "center",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: theme.spacing(2),
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(4),
  },
  "&::before": {
    content: '""',
    display: "block",
    position: "absolute",
    zIndex: -1,
    inset: 0,
    backgroundImage:
      "radial-gradient(ellipse at 50% 50%, hsl(210, 100%, 97%), hsl(0, 0%, 100%))",
    backgroundRepeat: "no-repeat",
    ...theme.applyStyles("dark", {
      backgroundImage:
        "radial-gradient(at 50% 50%, hsla(210, 100%, 16%, 0.5), hsl(220, 30%, 5%))",
    }),
  },
}));

export default function SignIn(props) {
  const [emailError, setEmailError] = React.useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = React.useState("");
  const [passwordError, setPasswordError] = React.useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = React.useState("");
  const [open, setOpen] = React.useState(false);

  // Snackbar state
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (await validateInputs()) {
      // If validateInputs returns true, it means login was successful
      // and we've already navigated to the new page
    }
  };

  const navigate = useNavigate();
  const { login } = useAuth();
  const validateInputs = async () => {
    const email = document.getElementById("email");
    const password = document.getElementById("password");

    const emailval = email.value;
    const passwordval = password.value;

    let isValid = true;

    // Validate email
    if (!emailval || !/\S+@\S+\.\S+/.test(emailval)) {
      setEmailError(true);
      setEmailErrorMessage("Please enter a valid email address.");
      isValid = false;
    } else {
      setEmailError(false);
      setEmailErrorMessage("");
    }

    // Validate password
    if (!passwordval || passwordval.length < 6) {
      setPasswordError(true);
      setPasswordErrorMessage("Password must be at least 6 characters long.");
      isValid = false;
    } else {
      setPasswordError(false);
      setPasswordErrorMessage("");
    }

    // If inputs are not valid, stop execution
    if (!isValid) {
      return false;
    }

    // Try to call the API only if validation passed
    const data = {
      email: emailval,
      password: passwordval,
    };
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/login`,
        data
      );
      console.log("Response data:", response.data);

      if (response.data.message === "Login successful") {
        console.log("User logged in successfully", response.data);
        login(response.data.userid);
        navigate("/et");
        return true;
      } else {
        setSnackbarMessage("Invalid credentials. Please try again.");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        return false;
      }
    } catch (error) {
      if (error.response) {
        console.error("There was an error signing in:", error);
        setSnackbarMessage(error.response.data.message || "Login failed.");
      } else {
        setSnackbarMessage("An error occurred. Please try again.");
      }
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return false;
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <AppTheme {...props}>
      <CssBaseline enableColorScheme />
      <SignInContainer direction="column" justifyContent="space-between">
        {/* <ColorModeSelect
          sx={{ position: "fixed", top: "1rem", right: "1rem" }}
        /> */}
        <Card variant="outlined">
          {/* <SitemarkIcon /> */}
          <Logo
            src={logo}
            alt="BillWeaver Logo"
            style={{ marginBottom: "0px" }}
          />
          {/* <Typography
            component="h1"
            variant="h4"
            sx={{ width: "100%", fontSize: "clamp(2rem, 10vw, 2.15rem)" }}
          >
            Sign in
          </Typography> */}
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap: 2,
            }}
          >
            <FormControl>
              <FormLabel
                htmlFor="email"
                sx={{
                  color: "white", // Set the text color to white
                  fontFamily: "Proxima-Nova-Font, sans-serif", // Optional: Set a custom font
                }}
              >
                Email
              </FormLabel>
              <TextField
                error={emailError}
                helperText={emailErrorMessage}
                id="email"
                type="email"
                name="email"
                placeholder="your@email.com"
                autoComplete="email"
                autoFocus
                required
                fullWidth
                variant="outlined"
                color={emailError ? "error" : "primary"}
                InputProps={{
                  sx: {
                    fontFamily: "Proxima-Nova-Font, sans-serif", // Apply font family to input text
                    "&::placeholder": {
                      fontFamily: "Proxima-Nova-Font, sans-serif", // Apply font family to placeholder
                    },
                  },
                }}
                sx={{
                  ariaLabel: "email",
                  fontFamily: "Proxima-Nova-Font, sans-serif",
                }}
              />
            </FormControl>
            <FormControl>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <FormLabel
                  htmlFor="password"
                  sx={{
                    color: "white", // Set the text color to white
                    fontFamily: "Proxima-Nova-Font, sans-serif", // Optional: Set a custom font
                  }}
                >
                  Password
                </FormLabel>
                <Link
                  component="button"
                  type="button"
                  onClick={handleClickOpen}
                  variant="body2"
                  sx={{
                    alignSelf: "baseline",
                    color: "white", // Set the text color to white
                    fontFamily: "Proxima-Nova-Font, sans-serif",
                    "&:hover": {
                      color: "#D9B441", // Hover text color
                    },
                  }}
                >
                  Forgot your password?
                </Link>
              </Box>
              <TextField
                error={passwordError}
                helperText={passwordErrorMessage}
                name="password"
                placeholder="••••••"
                type="password"
                id="password"
                autoComplete="current-password"
                autoFocus
                required
                fullWidth
                variant="outlined"
                color={passwordError ? "error" : "primary"}
                sx={{
                  fontFamily: "Proxima-Nova-Font, sans-serif", // Optional: Set a custom font
                }}
              />
            </FormControl>
            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
            <ForgotPassword open={open} handleClose={handleClose} />
            <Button
              type="submit"
              fullWidth
              // variant="contained"
              onClick={validateInputs}
              sx={{
                backgroundColor: "#FFE392", // Default background color
                "&:hover": {
                  backgroundColor: "#D9B441", // Hover background color
                },
                "&:disabled": {
                  backgroundColor: "#FFE392", // Ensure disabled state doesn't change color
                },
                color: "black", // Text color
                boxShadow: "none", // Remove any default shadow
                fontFamily: "Proxima-Nova-Font, sans-serif",
              }}
            >
              Sign in
            </Button>
            <Typography
              sx={{
                textAlign: "center",
                color: "white", // Set the text color to white
                fontFamily: "Proxima-Nova-Font, sans-serif",
              }}
            >
              Don&apos;t have an account?{" "}
              <span>
                <Link
                  href="/signup"
                  variant="body2"
                  sx={{
                    alignSelf: "center",
                    color: "white", // Set the text color to white
                    fontFamily: "Proxima-Nova-Font, sans-serif",
                    "&:hover": {
                      color: "#D9B441", // Hover text color
                    },
                  }}
                >
                  Sign up
                </Link>
              </span>
            </Typography>
          </Box>
        </Card>
        {/* Snackbar for success or error alerts */}
        <SnackbarAlert
          snackbarOpen={snackbarOpen}
          snackbarMessage={snackbarMessage}
          snackbarSeverity={snackbarSeverity}
          handleSnackbarClose={handleSnackbarClose}
        />
      </SignInContainer>
      //{" "}
    </AppTheme>
  );
}
