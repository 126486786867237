// old my transactions
import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  Box,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Popover,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  CircularProgress,
  Container,
} from "@mui/material";
import {
  Visibility,
  Edit,
  Delete,
  ArrowUpward,
  ArrowDownward,
  Close as CloseIcon,
  GetApp as DownloadIcon, // Add this import for the download icon
} from "@mui/icons-material";
import FilterListIcon from "@mui/icons-material/FilterList";
import axios from "axios";
import { CreditCard } from "lucide-react";
import NoTransactionsBox from "./NoTransactionsBox";
import AnalysisResult from "./AnalysisResult";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { useCurrency } from "../CurrencyContext";
import SnackbarAlert from "../components/SnackbarAlert";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontWeight: "bold",
    textAlign: "center",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    textAlign: "center",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:hover": {
    backgroundColor: theme.palette.action.selected,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  padding: "10px 20px",
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const MyTransactions = ({ userID, refreshTrigger }) => {
  const { currency } = useCurrency();
  const [transactions, setTransactions] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [openImageDialog, setOpenImageDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [editingTransaction, setEditingTransaction] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deletingTransactionId, setDeletingTransactionId] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const [categoryFilters, setCategoryFilters] = useState({});
  const [invoiceTypeFilters, setInvoiceTypeFilters] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [openFilter, setOpenFilter] = useState(null);
  const [selectedTransactions, setSelectedTransactions] = useState({});
  const [loading, setLoading] = useState(true); // Add this line

  // Snackbar state
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");

  const fetchTransactions = async () => {
    setLoading(true); // Set loading to true when starting to fetch
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/get_user_transactions/${userID}`
      );
      setTransactions(response.data);
    } catch (error) {
      console.error("Error fetching transactions:", error);
      if (error.response) {
        setSnackbarMessage(
          error.response.data.message || "Error fetching transactions."
        );
      } else {
        setSnackbarMessage("An error occurred. Please try again.");
      }
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return false;
    } finally {
      setLoading(false); // Set loading to false when fetch completes (success or error)
    }
  };

  useEffect(() => {
    if (userID) {
      fetchTransactions();
    }
  }, [userID, refreshTrigger]);

  const handleSort = (key) => {
    if (key === "purchase_summary" || key === "actions") return; // Ignore sort for these columns
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const getSortedTransactions = (transactions) => {
    if (!sortConfig.key) return transactions;

    return [...transactions].sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === "asc" ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === "asc" ? 1 : -1;
      }
      return 0;
    });
  };

  const sortedTransactions = getSortedTransactions(transactions);

  const handleViewImage = async (jobId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/get_job_image/${jobId}`
      );
      if (response.data && response.data.image) {
        setSelectedImage(`data:image/jpeg;base64,${response.data.image}`);
        setOpenImageDialog(true);
      } else {
        console.error("No image data received");
      }
    } catch (error) {
      console.error("Error fetching job image:", error);
      if (error.response) {
        setSnackbarMessage(
          error.response.data.message ||
            "Error fetching Error fetching job image."
        );
      } else {
        setSnackbarMessage("An error occurred. Please try again.");
      }
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return false;
    }
  };

  const handleEditTransaction = (transaction) => {
    const completeTransaction = {
      ...transaction,
      id: transaction.id,
      user_id: transaction.user_id,
      job_id: transaction.job_id,
      username: transaction.username,
      email: transaction.email,
      mobile_number: transaction.mobile_number,
      merchant_name: transaction.merchant_name,
      bill_no: transaction.bill_no,
      item_names: transaction.item_names,
      purchase_summary: transaction.purchase_summary,
      tax_amount: transaction.tax_amount,
      total_amount: transaction.total_amount,
      gst_number: transaction.gst_number,
      address: transaction.address,
      transaction_time: transaction.transaction_time,
      discount_amount: transaction.discount_amount,
      invoice_category: transaction.invoice_category,
      invoice_type: transaction.invoice_type,
    };

    setEditingTransaction(completeTransaction);
    setOpenEditDialog(true);
  };

  const handleSaveEdit = async (updatedTransaction) => {
    try {
      console.log("Editing transaction with userID:", userID);
      const processedTransaction = {
        ...updatedTransaction,
        user_id: Number(userID),
        tax_amount: parseFloat(updatedTransaction.tax_amount),
        total_amount: parseFloat(updatedTransaction.total_amount),
        discount_amount: parseFloat(updatedTransaction.discount_amount),
        transaction_time: new Date(
          updatedTransaction.transaction_time
        ).toISOString(),
        email: updatedTransaction.email,
        mobile_number: updatedTransaction.mobile_number,
        bill_no: updatedTransaction.bill_no,
        gst_number: updatedTransaction.gst_number,
        address: updatedTransaction.address,
        invoice_type_id: updatedTransaction.invoice_type_id,
      };

      const response = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/edit_transaction/${updatedTransaction.id}`,
        processedTransaction
      );

      // Update the local state immediately after successful edit
      setTransactions((prevTransactions) =>
        prevTransactions.map((transaction) =>
          transaction.id === updatedTransaction.id
            ? {
                ...transaction,
                ...processedTransaction,
                invoice_type: updatedTransaction.invoice_type,
              }
            : transaction
        )
      );

      setEditingTransaction(null);
      setOpenEditDialog(false);

      // Refresh the transactions list
      await fetchTransactions();

      setSnackbarMessage("Transaction updated successfully");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error updating transaction:", error);
      setSnackbarMessage(
        error.response?.data?.message || "Error updating transaction"
      );
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleDeleteTransaction = async () => {
    try {
      console.log("Deleting transaction with userID:", userID); // Debug log
      await axios.delete(
        `${process.env.REACT_APP_API_BASE_URL}/delete_transaction/${deletingTransactionId}?user_id=${userID}`
      );
      setOpenDeleteDialog(false);
      setDeletingTransactionId(null);
      fetchTransactions();

      setSnackbarMessage("Transaction deleted successfully");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error deleting transaction:", error);
      setSnackbarMessage(
        error.response?.data?.message || "Error deleting transaction"
      );
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const formatItemNames = (itemNamesString) => {
    try {
      const itemNames = itemNamesString
        .replace(/[{}]/g, "")
        .split(",")
        .map((item) => item.trim().replace(/^"(.*)"$/, "$1"));

      const displayedItems = itemNames.slice(0, 3).join(", ");
      const remainingCount = itemNames.length - 3;

      return (
        <Tooltip title={itemNames.join(", ")} arrow>
          <Typography
            noWrap
            sx={{
              maxWidth: 150,
              cursor: "pointer",
              margin: "0 auto",
              textAlign: "center",
            }}
          >
            {`${displayedItems}${
              remainingCount > 0 ? `, ...+${remainingCount}` : ""
            }`}
          </Typography>
        </Tooltip>
      );
    } catch (error) {
      console.error("Error parsing item names:", error);
      setSnackbarMessage(
        error.response.data.message || "Error parsing item names"
      );
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      //return false
      return itemNamesString;
    }
  };

  const getCategoryDisplay = (transaction) => {
    if (transaction.invoice_category === "Other") {
      return transaction.custom_category || "Other";
    }
    return transaction.invoice_category;
  };

  const handleFilterClick = (event, filterType) => {
    setAnchorEl(event.currentTarget);
    setOpenFilter(filterType);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
    setOpenFilter(null);
  };

  const handleFilterChange = (filterType, value) => {
    if (filterType === "category") {
      setCategoryFilters((prev) => ({ ...prev, [value]: !prev[value] }));
    } else if (filterType === "invoiceType") {
      setInvoiceTypeFilters((prev) => ({ ...prev, [value]: !prev[value] }));
    }
  };

  const getFilteredTransactions = () => {
    return sortedTransactions.filter((transaction) => {
      const categoryPass =
        Object.keys(categoryFilters).length === 0 ||
        categoryFilters[getCategoryDisplay(transaction)];

      const invoiceTypePass =
        Object.keys(invoiceTypeFilters).length === 0 ||
        (transaction.invoice_type &&
          invoiceTypeFilters[transaction.invoice_type.name]);

      return categoryPass && invoiceTypePass;
    });
  };

  const filteredTransactions = getFilteredTransactions();

  const renderFilterPopover = (filterType) => {
    const filters =
      filterType === "category" ? categoryFilters : invoiceTypeFilters;
    const setFilters =
      filterType === "category" ? setCategoryFilters : setInvoiceTypeFilters;
    const options = [
      ...new Set(
        sortedTransactions.map((t) =>
          filterType === "category"
            ? getCategoryDisplay(t)
            : t.invoice_type
            ? t.invoice_type.name
            : "N/A"
        )
      ),
    ];

    return (
      <Popover
        open={openFilter === filterType}
        anchorEl={anchorEl}
        onClose={handleFilterClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <List sx={{ maxHeight: 200, overflow: "auto" }}>
          {options.map((option) => (
            <ListItem
              key={option}
              dense
              button
              onClick={() => handleFilterChange(filterType, option)}
            >
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={filters[option] || false}
                  tabIndex={-1}
                  disableRipple
                />
              </ListItemIcon>
              <ListItemText primary={option} />
            </ListItem>
          ))}
        </List>
      </Popover>
    );
  };

  const handleSelectTransaction = (transactionId) => {
    setSelectedTransactions((prev) => ({
      ...prev,
      [transactionId]: !prev[transactionId],
    }));
  };

  const handleSelectAllTransactions = (event) => {
    const newSelectedTransactions = {};
    filteredTransactions.forEach((transaction) => {
      newSelectedTransactions[transaction.id] = event.target.checked;
    });
    setSelectedTransactions(newSelectedTransactions);
  };

  const handleExportCSV = () => {
    const dataToExport = filteredTransactions.filter(
      (transaction) => selectedTransactions[transaction.id]
    );
    const headers = [
      "Created By",
      "Merchant",
      "Amount",
      "Tax Amount",
      "Discount Amount",
      "Items",
      "Date",
      "Category",
      "Invoice Type",
      "Invoice Type Creator",
      "Bill Number",
      "Purchase Summary",
      "Email",
      "Mobile Number",
      "Address",
      "GST Number",
    ];

    const csvContent = [
      headers.join(","),
      ...dataToExport.map((transaction) =>
        [
          `"${transaction.creator_fullname || "Unknown"}"`,
          `"${transaction.merchant_name || ""}"`,
          transaction.total_amount.toFixed(2),
          transaction.tax_amount ? transaction.tax_amount.toFixed(2) : "",
          transaction.discount_amount
            ? transaction.discount_amount.toFixed(2)
            : "",
          `"${
            transaction.item_names
              ? transaction.item_names.replace(/[{}]/g, "").replace(/"/g, '""')
              : ""
          }"`,
          new Date(transaction.transaction_time).toLocaleDateString(),
          `"${transaction.invoice_category || ""}"`,
          `"${transaction.invoice_type ? transaction.invoice_type.name : ""}"`,
          `"${
            transaction.invoice_type
              ? transaction.invoice_type.creator_fullname
              : ""
          }"`,
          `"${transaction.bill_no || ""}"`,
          `"${
            transaction.purchase_summary
              ? transaction.purchase_summary.replace(/"/g, '""')
              : ""
          }"`,
          `"${transaction.email || ""}"`,
          `"${transaction.mobile_number || ""}"`,
          `"${
            transaction.address ? transaction.address.replace(/"/g, '""') : ""
          }"`,
          `"${transaction.gst_number || ""}"`,
        ].join(",")
      ),
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "transactions.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const handleDownloadImage = () => {
    if (selectedImage) {
      const link = document.createElement("a");
      link.href = selectedImage;
      link.download = "invoice_image.jpg";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  // Add this helper function near your other utility functions
  const truncateText = (text, wordLimit = 3) => {
    if (!text) return "N/A";
    const words = text.split(" ");
    const truncated = words.slice(0, wordLimit).join(" ");
    return words.length > wordLimit ? `${truncated}...` : truncated;
  };

  const canEditTransaction = (transaction) => {
    try {
      // Debug logging
      console.log("Transaction:", transaction);
      console.log("Current userID:", userID);

      // Check if user is transaction creator
      const isTransactionCreator =
        Number(transaction.user_id) === Number(userID);

      // Check if user is invoice type creator
      let isInvoiceTypeCreator = false;
      if (transaction.invoice_type && transaction.invoice_type.creator_id) {
        isInvoiceTypeCreator =
          Number(transaction.invoice_type.creator_id) === Number(userID);
      }

      // Debug logging
      console.log("Is transaction creator:", isTransactionCreator);
      console.log("Is invoice type creator:", isInvoiceTypeCreator);

      return isTransactionCreator || isInvoiceTypeCreator;
    } catch (error) {
      console.error("Error in canEditTransaction:", error);
      return false;
    }
  };

  return (
    <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "1.5rem",
          padding: "0.5rem",
          borderBottom: "2px solid #3f51b5",
          maxWidth: "300px",
          margin: "auto",
          marginTop: "2rem",
        }}
      >
        <CreditCard
          size={28}
          color="#3f51b5"
          style={{ marginRight: "0.75rem" }}
        />
        <Typography
          variant="h5"
          sx={{
            fontWeight: "bold",
            color: "#3f51b5",
            letterSpacing: "0.5px",
            textTransform: "uppercase",
          }}
        >
          My Transactions
        </Typography>
      </Box>

      {loading ? ( // Add this block to show loader while loading
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : sortedTransactions.length > 0 ? (
        <TableContainer
          component={Paper}
          sx={{ marginTop: "20px", boxShadow: 3 }}
        >
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell padding="checkbox" align="center">
                  <Checkbox
                    indeterminate={
                      Object.values(selectedTransactions).some(Boolean) &&
                      !Object.values(selectedTransactions).every(Boolean)
                    }
                    checked={Object.values(selectedTransactions).every(Boolean)}
                    onChange={handleSelectAllTransactions}
                  />
                </StyledTableCell>
                {[
                  { key: "creator", label: "Created By", filter: false },
                  { key: "merchant_name", label: "Merchant", filter: false },
                  { key: "total_amount", label: "Amount", filter: false },
                  { key: "item_names", label: "Items", filter: false },
                  { key: "transaction_time", label: "Date", filter: false },
                  { key: "invoice_category", label: "Category", filter: true },
                  { key: "invoice_type", label: "Invoice Type", filter: true },
                  {
                    key: "purchase_summary",
                    label: "Purchase Summary",
                    filter: false,
                  },
                  { key: "actions", label: "Actions" },
                ].map((column) => (
                  <StyledTableCell
                    key={column.key}
                    onClick={() => !column.filter && handleSort(column.key)}
                    sx={{
                      cursor:
                        !column.filter &&
                        column.key !== "purchase_summary" &&
                        column.key !== "actions"
                          ? "pointer"
                          : "default",
                      position: "relative",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {column.label}
                      {column.filter && (
                        <IconButton
                          sx={{ position: "absolute", right: 8 }}
                          size="small"
                          onClick={(e) =>
                            handleFilterClick(
                              e,
                              column.key === "invoice_category"
                                ? "category"
                                : "invoiceType"
                            )
                          }
                        >
                          <FilterListIcon />
                        </IconButton>
                      )}
                    </Box>
                    {sortConfig.key === column.key &&
                      !column.filter &&
                      column.key !== "purchase_summary" &&
                      column.key !== "actions" &&
                      (sortConfig.direction === "asc" ? (
                        <ArrowUpward fontSize="small" />
                      ) : (
                        <ArrowDownward fontSize="small" />
                      ))}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredTransactions.map((transaction) => (
                <StyledTableRow key={transaction.id}>
                  <StyledTableCell padding="checkbox">
                    <Checkbox
                      checked={!!selectedTransactions[transaction.id]}
                      onChange={() => handleSelectTransaction(transaction.id)}
                    />
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {transaction.creator_fullname || "Unknown"}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Tooltip title={transaction.merchant_name || "N/A"} arrow>
                      <Typography
                        noWrap
                        sx={{
                          maxWidth: 150,
                          cursor: "pointer",
                          margin: "0 auto",
                          textAlign: "center",
                        }}
                      >
                        {truncateText(transaction.merchant_name)}
                      </Typography>
                    </Tooltip>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {currency}
                    {transaction.total_amount.toFixed(2)}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Tooltip title={transaction.item_names || "N/A"} arrow>
                      <Typography
                        noWrap
                        sx={{
                          maxWidth: 150,
                          cursor: "pointer",
                          margin: "0 auto",
                          textAlign: "center",
                        }}
                      >
                        {truncateText(transaction.item_names)}
                      </Typography>
                    </Tooltip>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {new Date(
                      transaction.transaction_time
                    ).toLocaleDateString()}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {transaction.invoice_category}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {transaction.invoice_type ? (
                      <Box>
                        <Typography variant="body2">
                          {transaction.invoice_type.name}
                        </Typography>
                        <Typography variant="caption" color="textSecondary">
                          {transaction.invoice_type.creator_id === userID
                            ? "(Created by you)"
                            : `(Created by ${
                                transaction.invoice_type.creator_fullname ||
                                "Unknown"
                              })`}
                        </Typography>
                      </Box>
                    ) : (
                      "N/A"
                    )}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Tooltip title={transaction.purchase_summary} arrow>
                      <Typography
                        noWrap
                        sx={{
                          maxWidth: 150,
                          margin: "0 auto",
                          textAlign: "center",
                        }}
                      >
                        {transaction.purchase_summary}
                      </Typography>
                    </Tooltip>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <IconButton
                      size="small"
                      onClick={() => handleViewImage(transaction.job_id)}
                    >
                      <Visibility />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={() => handleEditTransaction(transaction)}
                      disabled={!canEditTransaction(transaction)}
                    >
                      <Edit />
                    </IconButton>
                    {canEditTransaction(transaction) && (
                      <IconButton
                        size="small"
                        onClick={() => {
                          setDeletingTransactionId(transaction.id);
                          setOpenDeleteDialog(true);
                        }}
                      >
                        <Delete />
                      </IconButton>
                    )}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box sx={{ textAlign: "center", mt: "5rem" }}>
          <NoTransactionsBox />
        </Box>
      )}

      <Dialog
        open={openImageDialog}
        onClose={() => setOpenImageDialog(false)}
        maxWidth={false}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        }}
      >
        <Box sx={{ position: "relative" }}>
          <IconButton
            aria-label="close"
            onClick={() => setOpenImageDialog(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "white",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.7)",
              },
            }}
          >
            <CloseIcon />
          </IconButton>
          <IconButton
            aria-label="download"
            onClick={handleDownloadImage}
            sx={{
              position: "absolute",
              right: 56, // Position it to the left of the close button
              top: 8,
              color: "white",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.7)",
              },
            }}
          >
            <DownloadIcon />
          </IconButton>
          {selectedImage ? (
            <img
              src={selectedImage}
              alt="Invoice"
              style={{
                maxWidth: "90vw",
                maxHeight: "90vh",
                objectFit: "contain",
              }}
            />
          ) : (
            <Typography sx={{ color: "white" }}>No image available</Typography>
          )}
        </Box>
      </Dialog>

      <AnalysisResult
        open={openEditDialog}
        onClose={() => {
          setOpenEditDialog(false);
          setEditingTransaction(null);
        }}
        result={editingTransaction}
        onSave={handleSaveEdit}
      />

      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Confirm Delete Transaction"}
        </DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete this transaction? This action cannot
            be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenDeleteDialog(false)}
            color="primary"
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            onClick={handleDeleteTransaction}
            color="primary"
            variant="contained"
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {renderFilterPopover("category")}
      {renderFilterPopover("invoiceType")}

      <Box sx={{ mt: 3, display: "flex", justifyContent: "center" }}>
        <StyledButton
          onClick={handleExportCSV}
          startIcon={<FileDownloadIcon />}
          disabled={!Object.values(selectedTransactions).some(Boolean)}
        >
          Export Selected as CSV
        </StyledButton>
      </Box>
      {/* Snackbar for success or error alerts */}
      <SnackbarAlert
        snackbarOpen={snackbarOpen}
        snackbarMessage={snackbarMessage}
        snackbarSeverity={snackbarSeverity}
        handleSnackbarClose={handleSnackbarClose}
      />
    </Container>
  );
};

export default MyTransactions;
